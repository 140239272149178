import React from 'react'
import {
    useParams, useNavigate
    
  } from 'react-router-dom';
import logo from '../images/logo.png'
import NewHeader from './HeaderNew';
import FooterNew from './FooterNew';
export default function ReturnPolicy() {
    const navigate = useNavigate();
  return (
    <div>
        <div className='about-us-hero'>
            <NewHeader/>
            {/* <div className='container-fluid my-desktop-menu'>
                <div className='row navbar-sec'>
                    <div className='col-xxl-8 col-xl-7 col-lg-7'><a href='/' ><img alt='file share website, send large files' className='logo-img' src={logo} /></a></div>
                    <div className='col-xxl-4 col-xl-5 col-lg-5'>
                        <ul className="nav justify-content-end nav-sec-nav">
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" aria-current="page" onClick={()=>navigate("/")} href="#">Home</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" aria-current="page" onClick={()=>navigate("/upload/")} href="#">Upload</button>
                            </li>
                            
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" onClick={()=>navigate("/about")} href="#">About Us</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c"  onClick={()=>navigate("/register")}>Sign Up</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" onClick={()=>navigate("/login")} >Sign In</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='container-fluid my-mobile-menu'>
                <div className='row navbar-sec'>
                    <div className='col-lg-9 col-6 col-sm col-md '><a href='#' onClick={()=>navigate("/")}><img className='logo-img mobile-logo' src={logo} /></a></div>
                    <div className='col-lg-3 col-6 col-sm col-md '>
                        <div className='text-end'>
                        <button className="btn-invisible-bg my-mobile-menu " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <i className="bi bi-list text-white bar-ico-menu"></i>
                        </button>
                        </div>
                    
                    </div>
                </div>
            </div>
            
            <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel"><a href='/' ><img alt='file share, send large files free throught helibucket'  className='logo-img-mob-menu' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1669208333/logo_1_ny5kuf_1_hcguue.png' /></a></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <ul className="dropdown-menu-v">
                            <li><a href='/' className="dropdown-item" onClick={()=>navigate("/")} >Home</a></li>
                            <li><a href='/upload/' className="dropdown-item mt-3" onClick={()=>navigate("/upload/")} >Upload</a></li>
                            <li><a href='/about/' className="dropdown-item mt-3" onClick={()=>navigate("/about/")} >About</a></li>
                            <li><a href='/contact/' className="dropdown-item mt-3 " onClick={()=>navigate("/contact/")} >Contact Us</a></li>
                            <li><a href='/register' className="dropdown-item mt-3 "  >Sign Up</a></li>
                            <li><a href='/login' className="dropdown-item mt-3 "  data-bs-toggle="modal" data-bs-target="#exampleModalforpop">Sign In</a></li>
                        </ul>
                    </div>
                    <div className='mt-5 text-center'>
                        <button className='rounded-pill btn-mob-menu'>Download Desktop Version</button>
                    </div>
                    <div className='mt-5 text-center'>
                        <div className='mob-menu-social text-center'>
                            <div className='me-3'>
                            <a className='me-3 color-aaa' href='https://www.facebook.com/HeliBucket'><i className="bi bi-facebook fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://instagram.com/heli_bucket'><i className="bi bi-instagram fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://www.linkedin.com/showcase/helibucket/'><i className="bi bi-linkedin fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://twitter.com/heli_bucket'><i className="bi bi-twitter fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://youtube.com/channel/UCykpS0MUzwhdcmRmWpY2uLQ'><i className="bi bi-youtube fs-3"></i></a>
                            </div>
                        
                        </div>
                    </div>
                    
                    
                </div>
            </div> */}
            <div className='container'>
                <div className='row'>
                    <p className='hero-heading-about mt-5 mb-5'>Refund Policy</p>
                </div>
            </div>
        </div>
        <div class="container">
      <div class="row">
        
        <div class="mt-3">
          <p class="bold h4"> <center><u><b>RETURN & REFUND POLICY</b></u></center></p>
          <p className='what-is-helibucket-des mt-3'>
            If, for any reason, you are not completely satisfied with the purchase, we invite you to review our policy on refunds and returns. The following terms are applicable for any products and services that you purchased with us.
          </p>
        </div>
        
        <div class="mt-3">
          <p class="bold h4"> <b> <span style={{'color':'#4285f4'}}>1. Disclaimer</span></b></p>
          <p className='what-is-helibucket-des mt-3'>
            1.1 Most products and services purchased from the company on www.helibucket.co.in are returnable within the return window, except those that are explicitly identified as not returnable.
          </p>
          <p className='what-is-helibucket-des mt-3'>
            1.2 For the products and services that are returned by the customer, the company will be liable to issue a refund to the original payment method (in case of pre-paid transactions) or to the bank account (in case of Pay on Delivery orders), the details for making a such refund and the timelines are detailed in the refund policy available.
          </p>
        </div>

        <div class="mt-3">
            <p class="bold h4"><b> <span style={{'color':'#4285f4'}}>2. Interpretation and Definitions</span></b></p>
            <p className='what-is-helibucket-des mt-3'>2.1 Interpretation: The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>
            <p className='what-is-helibucket-des mt-3'>2.2 Definitions: For the purposes of this Return and Refund Policy: </p>
            <p className='what-is-helibucket-des mt-3'>2.2.1 <b>"Company"</b> (referred to as either "the Company", "We", "Us" or "Our" in this Policy)</p>
            <p className='what-is-helibucket-des mt-3'>2.2.2 <b>“Products and Services”</b> refers to the items offered for sale on the Service which is a HeliBucket web and app version.</p>
            <p className='what-is-helibucket-des mt-3'>2.2.3 <b>"Orders"</b> means a request by you to purchase Products and services from us.</p>
            <p> className='what-is-helibucket-des mt-3'2.2.4 <b>"Service"</b> refers to the Websites.</p>
            <p className='what-is-helibucket-des mt-3'>2.2.5 <b>"Website"</b> refers to <a href="www.helibucket.co.in"><b>www.helibucket.co.in</b></a></p>
            
            <p className='what-is-helibucket-des mt-3'>2.2.6 <b>"You" </b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
          
        </div>

        <div class="mt-3">
          <p class="bold h4"><b>3. Your Order <span style={{'color':'#4285f4'}}>Cancellation Rights</span></b> </p>
          <p className='mt-3'>3.1 You are entitled to cancel your order within 15 days with a specified reason for doing so.</p>
            <p>3.2 In order to exercise your right of cancellation, you must inform us of your decision by means of a clear statement. You can inform us of your decision by:</p>
            <p>3.2.1 By visiting this page on our website: <a href="www.helibucket.co.in"><b><u>www.helibucket.co.in</u></b></a></p>
            <p>3.2.2 By sending us an email: <a href="#"><b><u>contact@heliware.co.in</u></b></a></p>
            <p>3.3 The company will reimburse you no later than 30 days from the day on which the company receives the returned Products and services. The company will use the same means of payment as you used for the Order.</p>
          

        </div>

        <div class="mt-3">
          <p class="bold h4"><b><span style={{'color':'#4285f4'}}>4. Conditions for Returns</span></b> </p>
          <p className='what-is-helibucket-des mt-3'>4.1 In order for the Products and services to be eligible for a return, please make sure that:</p>
          <p className='what-is-helibucket-des mt-3'>4.1.1 The Products and services were purchased in the last 15 days,</p>
          <p className='what-is-helibucket-des mt-3'>4.1.2 The product and services are not different from what was shipped to you,</p>
          <p className='what-is-helibucket-des mt-3'>4.1.3 The product and services are returned in original condition (including all the accessories therein).</p>
          <p className='what-is-helibucket-des mt-3'>4.2 The following Products and services cannot be returned:</p>
          <p className='what-is-helibucket-des mt-3'>4.2.1 The supply of Products and services made to your specifications or clearly personalized,</p>
          <p className='what-is-helibucket-des mt-3'>4.2.2 The supply of Products and services which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over,</p>
          <p className='what-is-helibucket-des mt-3'>4.2.3 The supply of Products and services which are, after delivery, according to their nature, inseparably mixed with other items,</p>
          <p className='what-is-helibucket-des mt-3'>4.2.4 Products and services may not be eligible for return in some cases, including cases of buyer's remorse such as incorrect model or color of the product and service ordered or incorrect product ordered,</p>
          <p className='what-is-helibucket-des mt-3'>4.2.5 Products and services marked as "non-returnable" on the product detail page cannot be returned. However, in the unlikely event of a damaged, defective, or wrong product and service being delivered to you, the company will provide a full refund or replacement, as applicable. The company may contact you to ascertain the damage or defect in the product prior to issuing a refund/replacement. The company reserves the right to pick up the product to ascertain the damage or defect in the product prior to issuing a refund/replacement.</p>

           
        </div>
      
        <div class="mt-3"> 
          <p class="bold h4"><b>5. Returning <span style={{'color':'#4285f4'}}>Products and services</span></b> </p>
          <p className='what-is-helibucket-des mt-3'>5.1 Applicable products and services are returnable within the applicable return window if you’ve received them in a condition that is damaged, has missing software or accessories, is defective or different from their description on the product detail page on <a href="www.helibucket.co.in"><b>www.helibucket.co.in</b></a></p>
          <p className='what-is-helibucket-des mt-3'>5.2 Products and services may be eligible for replacement only if the company has the exact same product available.</p>
          <p className='what-is-helibucket-des mt-3'>5.3 No additional information is required to return an eligible order unless otherwise noted in the category-specific policy.</p>
          <p className='what-is-helibucket-des mt-3'>5.4 If the replacement request is placed and the company does not have the exact same product available, a refund will be issued to you by the company.</p>
          <p className='what-is-helibucket-des mt-3'>5.5 In the event customers/users are found to misuse the return policy by excessively returning, canceling, or not accepting the orders placed, Heliware reserves the right to warn and/or suspend and/or block and/or terminate such customer accounts, as necessary. If a refund of such a customer is pending, then the company reserves the right to not refund such an amount to the customer.</p>
        </div>

        <div class="mt-3 mb-5">
          <p class="bold h4"><b>6. Queries related to <span style={{'color':'#4285f4'}}>Return and Refund Policy</span></b></p>
          <p className='what-is-helibucket-des mt-3'>If you have a query, concern, or complaint in relation to the return and refund policy please contact <a href="contact@heliware.co.in"><b>contact@heliware.co.in</b></a>.</p>
          <p>Our Return and Refund Policy was last updated on 25th January 2023.</p>
        </div>
      </div>
    </div>



        <div className="modal fade" id="exampleModalforpop" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
        <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
            <div className="text-center mb-5 mt-3">
            <p className="fs-3 text-center fw-bold"> Our Browser Version Coming Soon </p>
            <img alt='big data sharing platform helibucket' src="https://res.cloudinary.com/dgx4bwfhd/image/upload/v1667815210/New_Project_2_1_o43jrr.png" className="pc-img mt-3"></img>
            <p className="fs-5 text-center mt-3"> Download our desktop version<br/> & get 25 GB free cloud storage </p>
            <button className="btn btn-primary rounded-pill mt-3 pe-5 ps-5" onClick={()=>window.location.href='https://heliwarestorage.s3.us-west-2.amazonaws.com/bucket_data/heli_bucket/HeliBucket_Installer-0.1.exe'}>Download Now</button>
            </div>
        </div>
        
        </div>
    </div>
    </div>

    <FooterNew/>

        {/* <div className='footer-heli'>
            <div className='container'>
                <div className='row'>
                    
                    <div className='col-12 col-sm-12 col-md-6 mt-1 t-n-c-f'>
                        <a className='footer-text me-2' href='/terms-and-conditions/'>Terms & Condition</a>
                        <a className='footer-text me-2' href='/use-policy/'>Use Policy</a>
                        <a className='footer-text me-2' href='/return-policy/'>Refund Policy</a>
                        <a className='footer-text me-2' href='/contact/'>Help & Support</a>
                    </div>
                
                    <div className='col-12 col-sm-12 col-md-6'>
                        <p className=' color-aaa copyright-text'>
                        Copyright © 2023 Helibucket.co.in. All Rights Reserved.
                        </p>
                    </div>
                    
                </div>
            </div>
        </div> */}
    </div>
  )
}
