import React, { useRef, useState } from 'react';
// import { useDropzone } from "react-dropzone";
import FolderIcon from '../Images/folder-icon.svg'
import FileIcon from '../Images/file-icon.svg'
import Cloude from '../Images/cloude.svg'
import expand_icon from '../Images/expand_icon.svg'
import { NavLink } from 'react-router-dom';
import {useNavigate} from "react-router-dom";

export default function Sidebar({sidebar, sidebarText,openSideBar,closeSidebar , uploaderImage , uploadFileByInput , user_effect , sizeCalculation , BucketName
    ,used_size , BucketSize ,extender  }) {
// const [selectedFile, setSelectedFile] = useState(null);
// const [dataLink ,setDataLink] =useState("")
// const [accesssKey ,setAccesssKey] =useState("")
// const ListFileArr =[];
// const getEmailFromCookie = sessionStorage.getItem('email');
// const calculateInMbKbGB =() =>{
//     sizeCalculation(used_size)
// }

const sizeCalculation_withoutKbGb =(v) =>{
    if(v<1024 ){
        // v = parseFloat(v/1024).toFixed(2);
        return (v)
    }
    if(v>1024 && v<1024*1024){
        v = parseFloat(v/1024).toFixed(2);
        return (v)
    }else if(v>(1024*1024) && v<1024*1024*1024){
        v = parseFloat(v/(1024*1024)).toFixed(2) ;
        return (v)
    }else if(v>(1024*1024*1024)){
        v = parseFloat(v/(1024*1024*1024)).toFixed(2) ;
        return (v)
    }
} 
const navigate = useNavigate();
const privateShare =() =>{
    navigate("/private-share")
}
  const Open = (id) => { 

    document.getElementById(id).click();
  }
  const handleFolderInput = (e) => {
        const fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files ;
        show_files(fileList);
}
//  const handelDatalink = (e) =>{
//     // const datalink = e.target.value;
//     // setDataLink(datalink)
//     const datalink = e.target.value;
//     setDataLink(datalink)
//  }
// const handelAccessKey =(e)=>{
//     const accesskey = e.target.value;
//     setAccesssKey(accesskey) 
// }
// const GetdataOfPrivateShare =()=>{
//     const email = getEmailFromCookie;
//     const URL="https://helibucket.co.in/apis/share-to-me/";
// 	const requestOptions = {
// 		method: 'POST',
// 		headers: { 'Content-Type': 'application/json' },
// 		body: JSON.stringify({email:email, share_file:dataLink , access_code:accesssKey})
// 	};
// 	fetch(URL, requestOptions)
// 		.then(response => response.json())
// 		.then(data => {
// 			console.log('=====>',data);})
// }

// const { getRootProps, isDragActive } = useDropzone({
//     onDrop: (acceptedFiles) => {
//         // Handle dropped files and folders here
//         console.log(acceptedFiles);
//         // event.preventDefault();
//         setFiles(acceptedFiles);
//         show_files(acceptedFiles)
//         user_effect()
//       },

//       multiple: true,
//       directory: true,

//   });


//   const fileInputRef = useRef(null);
//   const handleFileChange = (event) => {
//         const files = event.target.files;
//         const fileArray = Array.from(files); // Convert FileList to array
//         // Do something with the fileArray
//         console.log(fileArray);
//         show_files(fileArray);
//   };
//   const handleButtonClick = () => {
//     if (fileInputRef.current) {
//       fileInputRef.current.click();
//     }
//   };
  const show_files =(files)=>{
    const elements = [];
    for (let idx = 0; idx < files.length; idx++) {
        const file = files[idx];
        elements.push(file);
      }
    console.log(elements);
    uploadFileByInput(elements);
    return elements;
      
  }

    return (
    <React.Fragment>
  
        <div  className={`d-flex flex-column flex-shrink-0 p-3 bg-white sidebar  ${uploaderImage  ? "sidebar_blur" :null}`}     style={{'width':sidebar }} >
            {sidebarText?
            <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto mx-auto link-body-emphasis text-decoration-none">
            <img className='logo-ico-small' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1671535830/heli_bucket_icon_tray-min_1_ql1yqw.png'/>
            </a>:
                <>
                {/* <p className={`position-absolute premimum heading`}>premium</p> */}
                <p className={`position-absolute premimum heading ${BucketSize>25 ?null :'d-none'}`}>premium</p>
                <div className='btn-group'>
                    <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                        <img className='logo-ico-full' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1669208333/logo_1_ny5kuf_1_hcguue.png' />
                        {uploaderImage}
                    </a>   
                    <a className='text-end'><button type="button" class="btn-close" onClick={openSideBar} aria-label="Close"></button></a>
                </div>
                </>
             }
            <hr/>
            
            <ul className="nav nav-pills flex-column mb-auto">
            <div class="btn-group dropend">
                <button className=" dropdown-toggle button-hover_none" type="button" data-bs-toggle="dropdown" aria-expanded="false"  style={{'backgroundColor':"white",'padding':'0'}}>
                    <li className="nav-item">
                        <a className="nav-link link-body-emphasis" aria-current="page">
                            <img className='drop-shadow' style={{"width":"35px","margin-left":"2px"}} src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1679905045/Add_New_1_vqfpkm.png' />
                            <span className={sidebarText?'d-none':'ms-3 fw-bold heading'}>Upload File/Folder</span>
                        </a>
                    </li>                
                </button>
                <ul class="dropdown-menu">
                   <li onClick={() => Open('file') }><a class="dropdown-item"   ><img src= {FileIcon}/> Upload File</a></li>
                    <li  onClick={() => Open('folder') }><a class="dropdown-item" ><img src= {FolderIcon} style={{'width':"20px","height":"auto"}}/> Upload Folder</a></li>
                    <input id='folder' type="file"  onChange={handleFolderInput} style={{ display: 'none' }} webkitdirectory='' mozdirectory='true' directory='true' />
                   <input id='file' type="file" onChange={handleFolderInput} multiple style={{ display: 'none' }}/>
                    
                </ul>
                
            </div>
            <div class="btn-group dropend">
                <button  onClick={privateShare} class=" dropdown-toggle button-hover_none" type="button"  data-bs-toggle="dropdown" aria-expanded="false" style={{'backgroundColor':"white",'padding':'0'}}>
                    <li className="nav-item">
                        <a className="nav-link link-body-emphasis" aria-current="page">
                            <img className='sidebar-ico drop-shadow' src='https://res.cloudinary.com/derifbjbg/image/upload/v1686566753/Share_with_me_1_d083hg.svg' />
                            <span className={sidebarText?'d-none':'ms-3 fw-bold heading '}>Shared With Me</span>
                        </a>
                    </li>                
                </button>
                {/* <ul class="dropdown-menu">
                <div className="mb-3 row">
                            <div className="ms-3 col-11">
                                <input type="text" placeholder='Enter Data link' onChange={handelDatalink} value={dataLink} className="form-control"  />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="ms-3 col-11">
                                <input type="text" placeholder='Enter access key ' onChange={handelAccessKey} value={accesssKey} className="form-control"  />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="ms-3 col-11">
                                <button type="button" onClick={GetdataOfPrivateShare} className="btn btn-success">Get Data</button>
                            </div>
                        </div>
                </ul> */}
            </div>
            {/* <NavLink to='/dashboard-premium'> */}

            <NavLink to={BucketSize>25 ? '/dashboard-premium' : '/dashboard' }>
            <div class="btn-group dropend">
                <button class=" button-hover_none" type="button" aria-expanded="false" style={{'backgroundColor':"transparent",'padding':'0'}}>
                    <li className="nav-item" onClick={user_effect}>
                        <a className="nav-link link-body-emphasis" aria-current="page">
                            <img className='drop-shadow' style={{"width":"50px","margin-left":"-4px"}} src='https://res.cloudinary.com/derifbjbg/image/upload/v1686566753/Bucket_1_hv0geq.svg' />
                            <span className={sidebarText?'d-none':'ms-3 fw-bold heading'}>{BucketName}</span>
                        </a>
                    </li>                
                </button>
            </div>
             </NavLink>
            </ul>
            <ul style={{'marginLeft':"-18px"}}>
            <div >
                <li className="link-body-emphasis mb-2">
                    <a className="nav-link link-body-emphasis" aria-current="page">
                    <div className="d-flex align-items-center">
                        <img className="sidebar-ico " style={{ width: "40px", height: "auto" }} src={Cloude} alt="Cloud Icon" />
                        <span className={`${sidebarText?'d-none':'ms-3 fw-bold heading'}`}><span className={`${BucketSize>25 ? null :"d-none"}`}>Premium </span>Storage</span>
                        {/* <span className={`${sidebarText?'d-none':'ms-3 fw-bold heading'}`}><span>Premium </span>Storage</span> */}
                    </div>
                    </a>
                </li>
                <li className="link-body-emphasis mb-2">
                    <a className="nav-link link-body-emphasis" aria-current="page"> 
                    <div className={`${sidebarText?'d-none':null}`}>
                        <div className="progress" style={{"width":"200px" , "height":"5px"}} role="progressbar" aria-label="Basic example" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}>
                            <div className="progress-bar " style={{"width":`${(((used_size/1024)/(BucketSize*1024*1024*1024))*100)}%`}}></div>
                        </div>
                    </div>
                    </a>
                </li>
                <li className="link-body-emphasis mb-2">
                    <a className="nav-link link-body-emphasis" aria-current="page">
                    <div className={`${sidebarText?'d-none':null}`}>
                        <p>{sizeCalculation(used_size/1024)}  of {BucketSize} GB used </p>
                    </div>
                    </a>
                </li>
            </div>
            </ul>
            <div className='text-center'>
                {sidebarText?
                <button style={{"backgroundColor":"transparent"}} className='button-hover_none'  onClick={extender? closeSidebar :null}><img src={expand_icon} style={{'width':"24px","height":"auto"}}/> </button>:
                <button className=' btn btn-primary'  onClick={openSideBar}><i class="bi bi-arrow-left-square"></i></button>
                }
            </div>
           
        </div>
    </React.Fragment>
  )
}
