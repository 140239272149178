import React from 'react'

export default function TermsandConditionContent() {
  return (
    <div className='mt-1'>
            <div className='container'>
                <div className='row'>
                <b>1. Introduction</b><br/>
                <p className='mt-3'>
                Welcome to SGR Labs Private Limited an IT Company in the field of Geospatial and Location Technology. SGR Labs Private Limited (“us”, “we”, or “our”) operates www.heliware.co.in and http://helibucket.co.in (hereinafter referred to as “Service”) having the Registered office at - Moti Vihar, Sector 41, Gurugram, Haryana. Our Privacy Policy governs your visit to www.heliware.co.in and http://helibucket.co.in, and explains how we collect, safeguard and disclose information that results from your use of our Service.
                </p><br/>

                <p>
                We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
        Our Terms and Conditions (“Terms”) govern all use of our Service and together with the Privacy Policy constitute your agreement with us (“Agreement”).
                </p>



                <span className='mt-5'><b>2. Definitions</b></span>
                <p className='mt-3'>
                <b>SERVICE</b> means the services identified & outlines on www.heliware.co.in and/or www.heliware.co.in and/or http://helibucket.co.in & its software website operated by SGR Labs Private Limited.
                </p>

                <p><b>PERSONAL DATA</b> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p> 
                <p><b>USAGE DATA</b> is data collected automatically either generated by the use of Service or from the Service infrastructure itself (for example, the duration of a page visit or through the interaction via email, phone call, form filling, software usage, etc).</p> 
                <p><b>COOKIES</b> are small files stored on your device (computer or mobile device).</p> 
                <p><b>DATA CONTROLLER</b> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p> 
                <p><b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> mean any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p> 
                <p><b>DATA SUBJECT</b> is any living individual who is the subject of Personal Data.</p> 
                <p><b>THE USER</b> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p> 


                <span className='mt-5'><b>3. Information Collection and Use</b></span>
                <p className='mt-3'>
                We collect several different types of information for various purposes to provide and improve our Service to you. The data collected or provided by the user is/can be used to optimize the solutions as per the requirement & desire of the user. 
                </p>
                <p>
                <b>a)</b> We can use the data collected on the ‘Contact Us’ page/link with third parties and our own subsidiary companies or entities.
                </p>
                <p>
                <b>b)</b> The data entered or collected after login on the web portal of the company is not shared with any third party. Such data can only be used by the company for customizing, updating, the Software/product/service, or creating related or other service(s). This same data shall also be used to keep the client/customer updated about the project/product/service opted by the client/customer. This data can/will be used to store order history and enable a delivery mechanism for the ordered items.
                </p>


                <p className='mt-5'><b>4. Types of Data Collected</b></p>
                <p><b>Personal Data</b></p>
                <p>
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally, identifiable information may include, but is not limited to:
                </p>
                <p>0.1	Email address</p>
                <p>0.2	First name and last name</p>
                <p>0.3	Phone number</p>
                <p>0.4	Address, Country, State, Province, ZIP/Postal code, City</p>
                <p>0.5	Name of Spouse/children/siblings/parents/relatives/friends and their data mentioned in above points (0.1-0.5)</p>
                <p>0.6	Problems/Issues related directly or indirectly to the user BUT disclosed by the user while availing our mentioned services related to points 0.1 – 0.5</p>
                </div>
                <br/>
                <p><b>Cookies and Usage Data</b></p>
                <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials, and other information that may be of interest to you. You may opt-out of receiving any, or all, of these communications from us by following the unsubscribe link.</p><br/>
                <p><b>Usage Data</b></p>
                <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through any device (“Usage Data”). And the data provided by your authorised personnel/people including your family/friends/siblings/parents/relatives/agents.</p><br/>
                <p>The data created/mined by the user like the details (age, address, payment mode, etc) shall be owned by the SGR Labs/the company/Heliware/HeliBucket. </p>
                <p>This Usage Data may include information such as your computer’s Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p><br/>
                <p>When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers, and other diagnostic data.</p><br/>

                <div className='mt-3'>
                    <p><b>Location Data</b></p>
                    <p>We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service.</p><br/>
                    <p>You can enable or disable location services when you use our Service at any time by way of your device settings.</p>
                </div>
                <div className='mt-3'>
                    <p><b>Tracking Cookies Data</b></p>
                    <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p><br/>
                    <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse our Service.</p><br/>
                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p><br/>
                    <p>Examples of Cookies we use:</p>
                    <p><b>0.1.	Session Cookies:</b> We use Session Cookies to operate our Service.</p>
                    <p><b>0.2.	Preference Cookies:</b> We use Preference Cookies to remember your preferences and various settings.</p>
                    <p><b>0.3.	Security Cookies:</b> We use Security Cookies for security purposes.</p>
                    <p><b>0.4.	Advertising Cookies:</b> Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</p>
                </div>
                <div className='mt-3'>
                    <p><b>Other Data</b></p>
                    <p>While using our Service, we may also collect the following information: sex, age, date of birth, place of residence and actual address, telephone number (work, mobile), office location, and other data.</p><br/>

                </div>

                <div className='mt-3'>
                    <p><b>5. Use of Data</b></p>
                    <p>SGR Labs/Heliware uses the collected data for various purposes:</p>
                    <p>0.1.	to provide and maintain our Service/product/software;</p>
                    <p>0.2.	to notify you about changes to our Service/product/software;</p>
                    <p>0.3.	to allow you to participate in interactive features of our Service when you choose to do so;</p>
                    <p>0.4.	to provide customer support & to provide customized solutions;</p>
                    <p>0.5.	to gather analysis or valuable information so that we can improve our Service/product/software;</p>
                    <p>0.6.	to monitor the usage of our Service/product/software;</p>
                    <p>0.7.	to detect, prevent and address technical issues;</p>
                    <p>0.8.	to fulfil any other purpose for which you provided it;</p>
                    <p>0.9.	to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</p>
                    <p>0.10. to provide you with notices about your account and/or subscription, including expiration and renewal notices, email instructions, etc.;</p>
                    <p>0.11. to provide you with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</p>
                    <p>0.12. in any other way we may describe when you provide the information;</p>
                    <p>0.13. for any other purpose.</p>
                </div>
                <div className='mt-3'>
                    <p><b>6. Retention of Data</b></p>
                    <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy & Legal Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p><br/>
                    <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
                </div>
                <div className='mt-3'>
                    <p><b>7. Transfer of Data</b></p>
                    <p>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p><br/>
                    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p><br/>
                    <p>SGR Labs/Heliware/HeliBucket will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
                </div>
                <div className='mt-3'>
                    <p><b>8. Disclosure of Data</b></p>
                    <p>We may disclose personal information that we collect, or you provide:</p><br/>
                    <p><b>0.1. Disclosure for Law Enforcement.</b></p>
                    <p>Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</p><br/>
                    <p><b>0.2. Business Transaction.</b></p>
                    <p>If we or our subsidiaries are involved in a merger, acquisition, or asset sale, your Personal Data may be transferred.</p><br/>
                    <p><b>0.3. Other cases. We may disclose your information also:</b></p>
                    <p>0.3.1. to our subsidiaries, delivery agents, warehouse(s), Delivery Partner, and affiliates;</p>
                    <p>0.3.2. to contractors, service providers, and other third parties we use to support our business;</p>
                    <p>0.3.3. to fulfil the purpose for which you provide it;</p>
                    <p>0.3.4. for the purpose of including your feedback on our website;</p>
                    <p>0.3.5. for any other purpose disclosed by us when you provide the information;</p>
                    <p>0.3.6. with your consent in any other cases;</p>
                    <p>0.3.7. if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</p>
                </div>
                <div className='mt-3'>
                    <p><b>9. Security of Data</b></p>
                    <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
                </div>
                <div className='mt-3'>
                    <p><b>10. Your Data Protection Rights Under General Data Protection Regulation (GDPR)</b></p>
                    <p>If you are a resident of India or the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR, & the IT Act.</p>
                    <p>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
                    <p>If you wish to be informed what Personal Data, we hold about you and if you want it to be removed from our systems, please email us at privacy@heliware.co.in</p><br/>
                    <p>In certain circumstances, you have the following data protection rights:</p>
                    <p>0.1.	the right to access, update, or delete the information we have on you;</p>
                    <p>0.2.	the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</p>
                    <p>0.3.	the right to object. You have the right to object to our processing of your Personal Data;</p>
                    <p>0.4.	the right of restriction. You have the right to request that we restrict the processing of your personal information;</p>
                    <p>0.5.	the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, and commonly used format;</p>
                    <p>0.6.	the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;</p>
                    <br/>
                    <p>Please note that we may ask you to verify your identity before responding to such requests. Please note, that we may not be able to provide Service without some necessary data.</p>
                    <br/>
                    <p>According to our policy, we agree to the following:</p>
                    <p>0.1.	users can visit our site anonymously;</p>
                    <p>0.2.	our Privacy Policy link includes the word “Privacy”, and can easily be found on the home page of our website;</p>
                    <p>0.3.	users are able to change their personal information by emailing us at privacy@heliware.co.in.</p>


                </div>
                <div className='mt-3'>
                    <p><b>11. A. Delete your personal information. </b></p>
                    <p> If you make this request, we will delete the personal information we hold about you as of the date of your request from our records and direct any service providers to do the same. In some cases, deletion may be accomplished through de-identification of the information. If you choose to delete your personal information, you may not be able to use certain functions that require your personal information to operate.</p>
                    <br/>
                    <p><b>11. B. To stop selling your personal information.</b></p>
                    <p>We don’t sell or rent your personal information to any third parties for any purpose. We do not sell your personal information for monetary consideration. However, under some circumstances, a transfer of personal information to a third party, or within our family of companies, without monetary consideration may be considered a “sale” under the law. You are the only owner of your Personal Data and can request disclosure or deletion at any time.</p>
                    <br/>
                    <p>If you submit a request to stop selling your personal information, we will stop making such transfers.</p>
                    <br/>
                    <p>Please note, that if you ask us to delete or stop selling your data, it may impact your experience with us, and you may not be able to participate in certain programs or services which require the usage of your personal information to function. But in no circumstances, we will discriminate against you for exercising your rights.</p>
                    <br/>
                    <p>To exercise your data protection rights described above, please send your request(s) by email: <i>privacy@heliware.co.in</i>.</p>
                    <p>You can share your complaint or concern with the Compliance Officer at <i>compliance@heliware.co.in</i> Your data protection rights, described above, are covered by the IT Act. </p>
                </div>
                <div className='mt-3'>
                    <p><b>12. Service Providers</b></p>
                    <p>We may employ third-party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p><br/>
                    <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                </div>
                <div className='mt-3'>
                    <p><b>13. Analytics</b></p>
                    <p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
                </div>
                <div className='mt-3'>
                    <p><b>14. CI/CD tools</b></p>
                    <p>We may use third-party Service Providers to automate the development process of our Service.</p>
                </div>
                <div className='mt-3'>
                    <p><b>15. Advertising</b></p>
                    <p>We may use third-party Service Providers to show advertisements to you to help support and maintain our Service.</p>
                </div>
                <div className='mt-3'>
                    <p><b>16. Behavioural Remarketing</b></p>
                    <p>We may use remarketing services to advertise on third-party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.</p>
                </div>
                <div className='mt-3'>
                    <p><b>17. Payments</b></p>
                    <p>a) We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g., payment processors, gateways, etc).</p><br/>
                    <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
                    <br/>
                    <p>b) The payments once made will not be <b>refunded/adjusted/transferred</b> under any circumstances. </p>
                    <br/>
                    <p>c) Any such claims as identified above (clause 17 b) will be processed/decided/rendered by SGR Labs/Heliware/HeliBucket only, at its own discretion. No appeals will be entertained in such cases and the decision will be considered final. </p>
                    <br/>
                    <p>d) The used indemnifies SGR Labs Private Limited (Heliware/HeliBucket) from any such claims of monetary loss due to wrong transaction(s) or any other reason. </p>
                </div>
                <div className='mt-3'>
                    <p><b>18. Terms & Conditions for Services</b></p>
                    <p><b>SGR Labs Private Limited (Heliware/HeliBucket)</b> through its website and via other media (including but not limited to calls, chats, text messages, books, YouTube channel/videos, social media platforms, or online video consultation) provides Consulting/Advisory/ Solutions to its client’s/customer’s IT & Product Delivery problems as per the list of services attached in Annexure A. </p>
                    <br/>
                    <p>The User/Customer/Client understands & agrees to indemnify SGR Labs Private Limited & www.heliware.co.in & http://helibucket.co.in & its directors/staff/employees/agents against any claims whatsoever arising out of the Services/Solutions provided to the User/Customer/Client. </p><br/>
                    <p>The User/Customer/Client understands & agrees to the following terms & conditions under which the Services/Solutions are provided by SGR Labs Private Limited (Heliware/HeliBucket) & its staff/directors/agents/employees.  </p><br/>
                    <p><b>a) SGR Labs Private Limited</b> & www.heliware.co.in & http://helibucket.co.in neither proposes nor commits any guarantee/warranty/promise for the success or failure or no-result or adverse result of services like (including their sub-heads & sub categories) mentioned on the attached Annexure A</p>
                    <p><b>b) SGR Labs Private Limited</b> & www.heliware.co.in &  http://helibucket.co.in will not be held responsible (in any way) for any adverse happening including (but not limited to) the loss of money, property (movable or immovable), life, health or wellbeing of the User/Client/Customer/its family members/relations/friends during or before or after the service sought by user/client/customer & provided by SGR Labs Private Limited & www.heliware.co.in & http://helibucket.co.in for the services outlined under products/solutions (including their sub-heads & sub categories) mentioned on the website and the attached Annexure A</p>
                    <p><b>c) SGR Labs Private Limited</b> & www.heliware.co.in &  http://helibucket.co.in will not be held responsible (in any way) for any wrongful information/duplication of data etc provided by the client/customer/user either intentionally or unintentionally and thus any result (through offered services) or lack of it thereof.</p>
                    <p><b>d) SGR Labs Private Limited</b> & www.heliware.co.in &  http://helibucket.co.in will not be held responsible (in any way) for any wrongful transaction done by the user/client/customer. Any such transaction and the equivalent amount will neither be refunded (in parts or complete) nor any claims are entertained.</p>
                    <p><b>e) SGR Labs Private Limited</b> & www.heliware.co.in &  http://helibucket.co.in will not be held responsible (in any way) for the quality/quantity of products/services provided by SGR Labs Private Limited & www.heliware.co.in &  http://helibucket.co.in for the services outlined under products or solutions (including their sub-heads & sub categories) mentioned on the website and the attached Annexure A. </p>
                    <p><b>f) SGR Labs Private Limited</b> will not be responsible for any loss of images or videos or data due to any issue at the user’s end or at the server end. Neither would the company be responsible for any loss of data due to an outage of internet or server etc.</p>
                    <p>g) SGR Labs has the right to edit, stop or monitor the service/product to ensure the quality and compliance to the legal framework.</p>
                    <p><b>h) SGR Labs Private Limited</b> & www.heliware.co.in &  http://helibucket.co.in will be under its rights to initiate any legal action/proceeding/suit against the user/client/customer who/which may indulge in inappropriate/illegal/unethical actions including (but not limited to) stalking/harassment/botheration/acts to defame/propagating false information or any material breach of the legal/privacy policy mentioned here or any terms which are implied & not explicitly mentioned here.</p>
                </div>

                <div className='mt-3'>
                    <p><b>19. Links to Other Sites</b></p>
                    <p>Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third-party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                    <br/>
                    <p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                </div>
                <div className='mt-3'>
                    <p><b>20. Children’s Privacy</b></p>
                    <p>Our Services are not intended for use by children under the age of 13 (“Child” or “Children”).</p><br/>
                    <p>We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
                </div>
                <div className='mt-3'>
                    <p><b>21. Changes to This Privacy Policy</b></p>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p><br/>
                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                </div>
                <div className='mt-3 mb-5'>
                    <p><b>22. Contact Us</b></p>
                    <p>If you have any questions about this Privacy Policy, please contact us by email at privacy@heliware.co.in. This Legal & Privacy Policy was created for SGR Labs Private Limited (Heliware/HeliBucket) on 6th Feb 2018.</p>
                </div>
            </div>


            <div className="modal fade" id="exampleModalforpop" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="text-center mb-5 mt-3">
                        <p className="fs-3 text-center fw-bold"> Our Browser Version Coming Soon </p>
                        <img alt='big data sharing platform helibucket' src="https://res.cloudinary.com/dgx4bwfhd/image/upload/v1667815210/New_Project_2_1_o43jrr.png" className="pc-img mt-3"></img>
                        <p className="fs-5 text-center mt-3"> Download our desktop version<br/> & get 25 GB free cloud storage </p>
                        <button className="btn btn-primary rounded-pill mt-3 pe-5 ps-5" onClick={()=>window.location.href='https://heliwarestorage.s3.us-west-2.amazonaws.com/bucket_data/heli_bucket/HeliBucket_Installer-0.1.exe'}>Download Now</button>
                        </div>
                    </div>
                    
                    </div>
                </div>
            </div>


            

        </div>
  )
}
