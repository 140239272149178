import React, { useRef, useState , useEffect } from 'react';
import '../../assets/Styles/dashboard.css'
import createFolder from "../Images/folder-icon.svg"
import FolderIcon from '../Images/folder-icon.svg'
import FileIcon from '../Images/file-icon.svg'
import loader_new from '../Images/loader_new.gif';
import Loader from '../Images/loader.gif';
import Swal from 'sweetalert2';
import ReactPlayer from 'react-player'
import CommentSection from './CommentSection';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {useNavigate} from 'react-router-dom';

// import FileIcon from './.././Images/file-icon.svg'
export default function PrivateFileShow() {
const StorageType = localStorage.getItem('storageType');
const navigate = useNavigate();
const [dataLink ,setDataLink] =useState("")
const [accesssKey ,setAccesssKey] =useState("")
const getEmailFromCookie = localStorage.getItem('email');
const getNameFromCookie = localStorage.getItem('name');
const [FileDatas, setFileDatas]=useState([]);
const [sBucket, setsBucket]=useState("");
const [loading1, setLoading1] = useState(false);
const [zipping , SetZipping] = useState(false)
const [basePath, setbasePath] = useState("");
const [Useremail , setUserEmail] = useState("")
const [SelectFilesArr, setSelectFilesArr]=useState([]);
const [sharedFileLoader , SetsharedFileLoader] =useState(false)
//view files by extension
const [viewfile , SetViewFile] = useState("") 
const [FileViewDiv , setFileViewDiv] =useState(false)
const [FileExtension , SetFileExtension] = useState("")
const[fileType , SetFileType] = useState("")
const [fileName, setFileName] =useState("")
const [BucketName, setBucketName]=useState("");
const [webPath , setWebPath ]= useState("");
const [loading, setLoading] = useState(true);
//save tiff file data 
const [TiffFileDownloadData , SetTiffFileDownloadData] = useState("")
const [FolderDelete ,SetFolderDelete ] = useState(false)
const [ShowFile  , setShowFile] = useState(false)
///view File collaboration details
const [CollaboerationLoader  , setCollaboerationLoader] = useState(false)
const [commentsinfo , setCommentinfo] = useState([])
const [FileCollaborationData , setFileCollaborationData] = useState([])
const [FileInfo , setFileinfo] = useState([])
//email don't have for share then this state work 
const [chatavilable , setChatAvailable] =useState(false)
const  displayFileContent=(content)=> {
    const fileContentDiv = document.getElementById('fileContent');
    fileContentDiv.innerText = content;
    }
//check folder or File
const [CheckFileOrFolder , SetCheckFolderOrFile] = useState("")

const downloadShowingFile =(data)=>{
    window.location.href = data;
} 
const docs = [
    { uri: viewfile ,
    fileType:FileExtension,
    fileName:fileName
     
}
  ];



  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const accesssKey = url.searchParams.get('access-code');
    console.log(accesssKey)
        if (getEmailFromCookie == null){
            localStorage.setItem('redirectAfterLogin', "/private-share-public-url/?access-code=BeiVnXyLPmkyt6fQSwzYp3");
            navigate("/login/")
        }
        else if (accesssKey === null || accesssKey === ''){
            Swal.fire("please don't have any access key")
        }
        else if (accesssKey.length=== 0){
            Swal.fire('please enter access key')
        }
       else{
        SetsharedFileLoader(true)
        const email = getEmailFromCookie;
        const URL="https://helibucket.co.in/apis/share-to-me/";
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({email:email,
                //  share_file:dataLink , 
                 access_code:accesssKey})
        };
        fetch(URL, requestOptions)
            .then(response => response.json())
            .then(jsondata => {
                if (jsondata.data === undefined){
                    setFileDatas([])
                }
                else{
                setUserEmail(jsondata["email"]);
                setWebPath(jsondata["bucket_name"])
                
                for (let i = 0; i < jsondata.data.length; i++) {
                    
                    // typeof jsondata.data[i]  === "object" ?  
                    FileDatas.push([jsondata.data[i].filename,jsondata.data[i].edit])
                    // : FileDatas.push([jsondata.data[i],false])
                    
                }
                }
                
                SetsharedFileLoader(false)
                // console.log("filename ---------------------->",FileDatas);
            })
       }
            
    
    // }
  }, []);

  const GetdataOfPrivateShare =()=>{
    //setFileDatas([])
    if (accesssKey.length== 0){
         
    Swal.fire('please enter access key')
    }
   else{
    SetsharedFileLoader(true)
    const email = getEmailFromCookie;
    const URL="https://helibucket.co.in/apis/share-to-me/";
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({email:email,
            //  share_file:dataLink , 
             access_code:accesssKey})
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(jsondata => {
            if (jsondata.data === undefined){
                setFileDatas([])
            }
            else{
                // console.log(jsondata["bucket_name"])
            setUserEmail(jsondata["email"]);
            setWebPath(jsondata["bucket_name"])
            // console.log("=====================>",)
            for (let i = 0; i < jsondata.data.length; i++) {
                // let temp_arr = jsondata.data[i].filename.split("/");
                
                typeof jsondata.data[i]  === "object" ?  FileDatas.push([jsondata.data[i].filename,jsondata.data[i].edit])
                : FileDatas.push([jsondata.data[i],false])
                
            }
            }
            
            SetsharedFileLoader(false)
            console.log("filename ---------------------->",FileDatas);
        })
   }
        

}
const FileDeleteFromFolder= async ()=>{
    SetFolderDelete(false)
    setFileViewDiv(false);
    const basePath ="/var/www/html/file_media/tiif_to_png/"+BucketName+"/" 
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ base_path: basePath })
    };
    try {
        const response = await fetch('https://helibucket.co.in/apis/png_file_delete/', requestOptions);
        const data = await response.json();
        console.log('=====>', data);
    } catch (error) {
        console.error('Error:', error);
    }
}



// const hitme=()=>{
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ country:"India",
//             user_type:"service provider",
//             contract_type:"service agreement",
//             file:JSON.stringify(json_data)})
//     };
//     fetch('https://ai.heliware.co.in/file_conversion/tsa_legal_automation2/' , requestOptions)
//         .then(response => response.json())
//         .then(data=>{
//             console.log(data)
//         })
// }

const viewComment =(item)=>{
    setCollaboerationLoader(true)    
    const  bashpath = webPath+"/"+item
    // console.log("bashpath --------------->",bashpath)
    const requestOption = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: Useremail, bashpath:bashpath  })
      }
      fetch('https://helibucket.co.in/apis/fie_info_and_collaboration/', requestOption)
          .then(response => response.json())
          .then(data =>{
            console.log(data) 
                const fileInfoEntry = { fileName: data['fileName'], size: data['size'] };
                FileInfo.push(fileInfoEntry)
                // setFileCollaborationData(data['EmailList']);
                // console.log("---------------------------------->emailIst",data['EmailList'] , )
                if(data['EmailList'].length === 0){
                    setCollaboerationLoader(false)
                    setChatAvailable(false)  
                    console.log({"messege":"no share email available"})
                }
                else{
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ owner_email : Useremail ,owner_access_code :accesssKey , file_path :FileInfo[0].fileName ,share_email_list :data['EmailList']   })
                    };
                    fetch('https://helibucket.co.in/apis/start-commenting/', requestOptions)
                        .then(response => response.json())
                        .then(data => {
                            console.log('=====>',data);
                            setSelectFilesArr([]);
                            setChatAvailable(true)
                            setCommentinfo(data["comment_info"])
                            setCollaboerationLoader(false)
                        })
                }
                
            } );
    // let buckeName = String(BucketName) ;
    

}
const clearCollaborationState =()=>{
    setFileinfo([])
    setFileCollaborationData([])
}
const fetchTextFile = async (url) => {
    try {
      const response = await fetch(url);
      const text = await response.text();
    //   console.log("this is text response",text)
    if (text==""){
        displayFileContent("No Text Found in This Document")
    }
      displayFileContent(text)
    } catch (error) {
        displayFileContent("Error fetching text file:")
      console.log('Error fetching text file:', error);
    }
  };

  const fetchPDFFile  = async (url) => {
    try {
      const response = await fetch(url);
        SetViewFile(url);
    
    } catch (error) {
        displayFileContent("Error fetching text file:")
      console.log('Error fetching text file:', error);
    }
  };

const getFileExtension = (fileName)=> {
    const file = fileName;
    const dotIndex = file.lastIndexOf('.');
    if (dotIndex === -1 || dotIndex === file.length - 1) {
        return ''; // No extension found or dot is the last character
    }
    return file.substring(dotIndex + 1);
}

const extractLastParts =(filenames) =>{
    const parts = filenames.split('/');
    const lastPart = parts[parts.length - 1];
    return lastPart;
}


const  openFile = async (item ,fileSize , key) =>{
    console.log(item)
    const BucketName = item.split("/")[0];
    const SplitItem = item.split("/");
    const File_name = SplitItem[SplitItem.length  - 1];
    console.log(File_name)
    console.log(BucketName)
    SelectFilesArr.push(item );
    console.log(typeof(SelectFilesArr))
    setFileName(item)
    setShowFile(true)
    const Filextension = getFileExtension(item)
    let fileExtension =  Filextension
    const supportedVideoExtensions = ["mp4", "webm", "ogg", "mkv","mp3"];
    const supportedImageFormats = ["jpeg", "jpg", "png", "gif", "svg", "webp","bmp"];
    const supportedTextFormats = ["txt", "csv","htm","kml", "json", "xml","html","py","php","js","c","cpp","java","css","net","cs","yml","odt","log","jsx","topojson","geojson","wkt"];
    const SupportedPdfFormats = ["pdf"]
    const SupportTiffImage =["tiff","tif"]
    const SupportDocumentAvailableFormat =["odt","doc","docx","ppt","pptx","xls","xlsx"]
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({email: getEmailFromCookie , file_path:SelectFilesArr[0],file_size:fileSize})
    };
    if (supportedVideoExtensions.includes(fileExtension)) {
    SetFileType("video")
    await fetch('https://helibucket.co.in/apis/single-file-download/', requestOptions)
    .then(response => response.json())
    .then(data => {
        setFileViewDiv(true)
        SetViewFile(data)
        setShowFile(false)
        VideoIdm_id_change()
        setSelectFilesArr([]);
    })
    
    console.log("video")
    }
    else if (supportedImageFormats.includes(fileExtension)){
    SetFileType("Image")
    await fetch('https://helibucket.co.in/apis/single-file-download/', requestOptions)
    .then(response => response.json())
    .then(data => {
        fetchPDFFile(data)
        setFileViewDiv(true)
        setShowFile(false)
        setSelectFilesArr([]);
    })
    console.log("image")
    }
    else if(supportedTextFormats.includes(fileExtension)){
        SetFileType("text")
        await fetch('https://helibucket.co.in/apis/single-file-download/', requestOptions)
        .then(response => response.json())
        .then(data => {
            fetchTextFile(data)
            setFileViewDiv(true)
            SetViewFile(data)
            setShowFile(false)
            setSelectFilesArr([]);
        })
    console.log("text")
    }
    else if(SupportDocumentAvailableFormat.includes(fileExtension)){
        SetFileType("document")
        SetFileExtension(fileExtension)
        await fetch('https://helibucket.co.in/apis/single-file-download/', requestOptions)
        .then(response => response.json())
        .then(data => {
            setFileViewDiv(true);
            SetViewFile(data);
            setShowFile(false)
            setSelectFilesArr([]);
        })
    }
    else if(SupportTiffImage.includes(fileExtension)){
        SetFileType("TiffImage")
        SetFileExtension(fileExtension)
        const response1 = await fetch('https://helibucket.co.in/apis/single-file-download/', requestOptions);
        const data1 = await response1.json();
        console.log(data1)
        SetTiffFileDownloadData(data1)
        const requestOption = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ imagepath: data1, ImageName: File_name, bucketname: BucketName }),
        };
        const not_found_img= 'https://helibucket.co.in/file_media/tiif_to_png/no-results.png';
        const response2 = await fetch('https://helibucket.co.in/apis/tiff_to_png/', requestOption);
        const data2 = await response2.json();
        if (not_found_img !== data2){
            SetFolderDelete(true)
        }
        setFileViewDiv(true);
        SetViewFile(data2);
        setShowFile(false)
        setSelectFilesArr([]);
    }
    else if(SupportedPdfFormats.includes(fileExtension)){
        SetFileType("pdf")
        SetFileExtension(fileExtension)
        const response1 = await fetch('https://helibucket.co.in/apis/single-file-download/', requestOptions);
        const data1 = await response1.json();
        SetTiffFileDownloadData(data1)
        const requestOption = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ imagepath: data1, ImageName: File_name, bucketname: BucketName , type : "pdf" }),
        };
        const not_found_img= 'https://helibucket.co.in/file_media/tiif_to_png/no-results.png';
        const response2 = await fetch('https://helibucket.co.in/apis/tiff_to_png/', requestOption);
        const data2 = await response2.json();
        console.log(data2)
        if (not_found_img !== data2){
            SetFolderDelete(true)
        }
        setFileViewDiv(true);
        SetViewFile(data2);
        setShowFile(false)
        setSelectFilesArr([]);
    }
    else{
        setShowFile(false)
        Swal.fire({
            title: 'No Preview available',
            icon: 'error',
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonText: '<i class="bi bi-download"></i> Download'
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
                title: 'Download will Started!',
                icon: 'success',
              showCloseButton: true,
              showConfirmButton: false,
            }
            )
          }
            });
            setSelectFilesArr([]);
    }
    
    console.log(SelectFilesArr)
}

const FileViewOff =() =>{
    setFileViewDiv(false);
    SetViewFile("");
    setFileName("");
}



//call sub foder data start
const SubFolderCall = (web_path) =>{
    setLoading(true);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: Useremail , path:web_path })
    };
    fetch('https://helibucket.co.in/apis/subfolder-files-list/', requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log('=====>',data);
            const temp_arr_files=[];
            for(let i=0; i<data['data'].length; i++){
                let datas = data['data'][i][0];
                temp_arr_files.push([datas,false]);
            }
            setFileDatas(temp_arr_files)
            setLoading(false);
            
    });
}
//call sub foder data end


///get subfolder data start
const getSubFolderdata =(item) =>{
    setLoading(true);
    const web_path = item +"/";
    // webPath +item+"/";
    setWebPath(web_path);
    SubFolderCall(web_path);
      console.log("webpath sub api: ",web_path);
}
///get subfolder data end


const sizeCalculation =(v) =>{
    if(v<1024 ){
        // v = parseFloat(v/1024).toFixed(2);
        return (v+" bytes")
    }
    else if(v>1024 && v<1024*1024){
        v = parseFloat(v/1024).toFixed(2);
        return (v+" KB")
    }else if(v>(1024*1024) && v<1024*1024*1024){
        v = parseFloat(v/(1024*1024)).toFixed(2) ;
        return (v+" MB")
    }else if(v>(1024*1024*1024)){
        v = parseFloat(v/(1024*1024*1024)).toFixed(2) ;
        return (v+" GB")
    }
}  


const fileFolderChecking = (item) => {
    return item.includes('.');
  }
const downloadSingleItem = (item , key) =>{
    SetZipping(true)
    SelectFilesArr.push(item);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: Useremail , fileList:SelectFilesArr})
        };
        fetch('https://helibucket.co.in/apis/user-file-download/', requestOptions)
        .then(response => response.json())
        .then(data => {
            SetZipping(false)
            console.log('=====>',data);
            console.log(basePath +"/"+ item)
            window.location.href = data;
            setSelectFilesArr([]);
        })}

const VideoIdm_id_change=()=>{
    if (fileType == "video") {
        console.log("hello sir")
        var videoElement = document.getElementById('video_id'); // Replace 'yourVideoId' with your video element's actual ID
        // Update the "__idm_id__" attribute
        videoElement.setAttribute('__idm_id__', '1234'); // Replace 'newIdmId' with the desired value

    }
}
    const handelDatalink = (e) =>{
        const datalink = e.target.value;
        setDataLink(datalink)
     }
    const handelAccessKey =(e)=>{
        const accesskey = e.target.value;
        setAccesssKey(accesskey) 
    }

    
  return (
    
    <div>
        <div className="access-bucket-main">
            <div className="container-fluid">
                <div className="row ">
                {/* <div className="col-lg-5 form-sec-left">
                    <div className="form-card-acc-bkt " style={{"height":"200px"}}>
                    <div className="mb-3 text-center">
                        <input type="text" onChange={handelAccessKey} value={accesssKey} name="path" placeholder="Access code" className="acc-bkt-form-field" id=""
                        />
                    </div>
                    <div className="mb-3 text-center">
                        <button onClick={GetdataOfPrivateShare} type='button' className="button-for-acc-bkt">Access Shared Files <span class={`spinner-border spinner-border-sm ${sharedFileLoader ? null:'d-none' } `} role="status" aria-hidden="true"></span> </button>
                    </div>
                    </div>
                </div> */}
               

                <div className={`loader ${sharedFileLoader ? null:'d-none' }  `} >
                    <img className='file-icon-list' src={Loader} />
                </div>
                <div className={`col-12 top-0 border-start d-flex justify-content-center align-items-center ${sharedFileLoader ? 'd-none' :null} `}>
                <table className='table'>
                <thead>
                <tr className=' table_color table-Header'>
                    <th scope='col'>Name</th>
                    {/* <th scope='col'>File Size</th> */}
                    <th scope='col'>Action</th>
                </tr>
                </thead>
                <div className='d-flex align-items-start justify-content-start pt-2 ps-3'>
                    <button type="button" class="btn back-btn" style={{"padding":"5px 3px 5px 3px" }} onClick={()=>StorageType=== "premimum" ?navigate('/dashboard-premium') : navigate('/dashboard')}> 
                    <i class="bi bi-arrow-left-short"></i>
                    </button>
                </div>
                {FileDatas.length === 0 ? 
                (<tr class="">
                    <td class="acc-bkt-td text-center" colspan="3">No data present here...</td>
                    {/* <button onClick={hitme}>hii</button> */}

                </tr> ):
                (null)
                }
                <tbody  >
                {FileDatas.map((item, key) => (
                    // {}
                    
                    <tr  className='p-3 file-hover' key={key}>
                    <td className='p-3 ' style={{'marginLeft':"20px"}}>
                        {fileFolderChecking(item[0]) ? <img className="folder-icon" src={FileIcon} />
                            : <img className="folder-icon" src={FolderIcon} />
                        }
                        <div  className='fw-medium btn-group'>
                        &nbsp;
                        <span className='text-limit'>
                            {/* {item[0]} */}
                            {extractLastParts(item[0])}
                            </span>
                        </div>
                    </td>
                    <td className='fw-medium'>
                        <div>
                        <button
                            className={`me-3 buttons-icon`}
                            onClick={  fileFolderChecking(item[0]) ? () => openFile(item[0], key) :() => getSubFolderdata(item[0])}>
                            <i className='options-icons bi bi-eye fs-6 '></i>
                        </button>
                        {item[1] === true ? (<button
                            className={`me-3 buttons-icon`}
                            onClick={() => downloadSingleItem(item[0], key)}>
                            <i className='bi options-icons bi-download fs-6  '></i>
                        </button>) :(null)  }
                        <button type="button"  className={`me-3 buttons-icon ${SelectFilesArr.length > 1 ? 'd-none' : null}`} data-bs-toggle="offcanvas" data-bs-target="#CommentSection" aria-controls="CommentSection"
                        onClick={() => viewComment(extractLastParts(item[0]))}>
                            <i className='bi options-icons bi-chat-left-text fs-6 d-block'></i>
                        </button>
                        </div>
                    </td>
                    </tr>
                ))}
                </tbody>
        </table>    
                </div>
                </div>
            </div>
            </div>

            <div className={`background-not-clickable ${FileViewDiv ? "d-flex": "d-none"} `}>
        <div className='content_showing d-block'>
            <div className='row bg-transparent'>
                <div className='col-6 bg-transparent'>
                    <p className='p-2 ms-2 text-limit'>{extractLastParts(fileName)}</p>
                </div>
                <div className='col-6 text-end bg-transparent'>
                    {/* <button className='p-2 bg-white' type="button" onClick={()=>downloadShowingFile(fileType === "TiffImage" || fileType === "pdf"? TiffFileDownloadData:viewfile )}><i className="bi options-icons bi-download fs-6 " ></i></button> */}
                    <button className='p-2 me-4 bg-white'  type="button"  onClick={FolderDelete===true ?FileDeleteFromFolder :FileViewOff} aria-label="Close"><i class="bi bi-x-lg fs-6"></i></button>           
                </div>           
            </div>
            <div className=' d-flex  align-items-center justify-content-center'>
            {fileType === "video" ? (
                    <div>
                        <div className='ImageShowingStyle'><ReactPlayer  autoplay="autoplay" id = "video_id" width="720" height="450" __idm_id__ ="123" controls url={`${viewfile}`} ></ReactPlayer> </div>
                        <div className='image-hide2'></div>
                    </div>

                    
                    ) : fileType === "Image" ? (
                    
                    <div style={{zIndex:"999",display: "flex"}} >
                        <div className='ImageShowingStyle'><img src={viewfile}  className='image-size' /></div>
                        <div className='image-hide'></div>
                    </div>
                    ) :fileType === "text" ? (
                        <p id="fileContent"></p>

                        )
                        :fileType === "pdf" ? (
                            <iframe
                                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${viewfile}`}frameBorder="0"
                                scrolling="auto"
                                style={{ width: "600px", height: "500px" }}
                                ></iframe>
                            )
                        :fileType === "TiffImage" ? (
                            <div style={{zIndex:"999",display: "flex"}} >
                                <div className='ImageShowingStyle'><img src={viewfile}  className='image-size' /></div>
                                <div className='image-hide'></div>
                            </div>    
                            )
                        :fileType === "document" ? (
                            <div className='' >
                                <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} style={{height:"600px",width:"50rem"}}/>
                            </div>                            
                            ) 
                        : null}
            </div>
        </div>
    </div>
    <div className={`background-not-clickable ${ShowFile ? "d-flex": "d-none"} `}>
        <div className={`new_loader ${ShowFile ? null : "d-none"} `} >
            <img className='file-icon-list ' style={{"width":"80px" , "height":"auto"}} src={loader_new} />
        </div>
    </div>                        
    <CommentSection FileInfo ={FileInfo} collaboration = {FileCollaborationData} CollaboerationLoader= {CollaboerationLoader} clearCollaborationState={clearCollaborationState} chatavilable = {chatavilable} commentsinfo ={commentsinfo} share_email={getEmailFromCookie}   viewComment ={viewComment} user_name={getNameFromCookie}/>

    </div>

    
  )
}
