import React ,  {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import "../../../src/assets/Styles/modal.css";
import '../../assets/Styles/dashboard.css'
import Loader from "../../components/Images/loader_new.gif"
import CreatLink from '../Images/creating_link.gif';
import copy from "copy-to-clipboard";
import Swal from 'sweetalert2';
import ShareButtons from "./ShareButtons";
import CollaborationInfo from './CollaborationInfo';
export default function ModalShare({SelectedFile , UserEmail , closeLinkData , DeSelectAll ,email_list, collaboration,CollaboerationLoader,
clearCollaborationState}) {
const [sharLinkActive , setShareLink] = useState(false);
const [SecretSharLinkActive , setSecretShareLink] = useState(false);
const [shareLinkUrl , setShareLinkUrl] = useState("");
const [loading, setLoading] = useState(false);
const [copyBtnTxt, setCopyBtnTxt] = useState('Copy');
const [copyBtnColor, setCopyBtnColor]=useState(false);
const [email , setEmail ] = useState("");
const navigate = useNavigate();
const [emailList,setEmailList] = useState([]);
const [senderEmail , setSenderEmail] = useState([]);
const [reciverEmail , setReciverEmail] = useState([]);
const [CreatingLink , setCreatingLink] =useState(false);
const [searchingEmail , SetSearchingemail] = useState(false);
const StorageType = localStorage.getItem('storageType');
const [FileStatus , SetFileStatus] =useState(false);
const [Sharepublic , setSharepublic ] = useState(true)

//get zip input 
const [showInput, setShowInput] = useState(false);
// const [zipPasswordValue, setzipPasswordValue] = useState('');
const [zipPass , setZipPass] = useState(null)
const [ziperror, setZipError] = useState('');
//get and set download limit 
const [downloadLimit , setDownloadLimit] = useState(5);
//get and set link expired
const [linkExpiredTime , setLinkExpired] = useState("")
const [hours, setHours] = useState(6);
const [days, setDays] = useState(0);
const [errorExpired, setErrorExpired] = useState('');
const [timeExpirederror , setExpiredError ] = useState(true)
//get and set Access code 
const [accessCode , setAccesCode]=useState(false)
const [limiterror, setLimitError] = useState('');
const currentDate = new Date();
const sixHoursLater = new Date(currentDate.getTime() + 6 * 60 * 60 * 1000);
const [selectedDate, setSelectedDate] = useState(sixHoursLater);


const [firstCommentMsg , setFirstCommentMessge] = useState("")
//get time zone for sending mail perpose   
const [timeZone, setTimeZone] = useState('');
// const handleDaysChange = (event) => {
//   setDays(event.target.value);
//   if (hours === '0' || hours.length === 0) {
//     setErrorExpired(event.target.value === '0' || event.target.value.length === 0  ? "Days cannot be 0 when hours is 0" : "");
//     if(event.target.value === '0' || event.target.value.length === 0){
//       setSharepublic(false)
//     }
//     else{
//       Checklimit()
//     }
//   }
 
// };    
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const handleDateChange = (e) => {
  const newDate = new Date(e.target.value);
  const minValidDate = new Date(currentDate.getTime() + 6 * 60 * 60 * 1000); // 6 hours in milliseconds
  const maxValidDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days in milliseconds
  // console.log(userTimeZone)
  if (newDate >= minValidDate && newDate <= maxValidDate) {
    setErrorExpired('');
    const timeDifference = newDate - currentDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    setSharepublic(true)
    setHours(hoursDifference)
    setDays(daysDifference)
    
    Checklimit()
    console.log(`days ${daysDifference} and hours ${hoursDifference}`);
  } else {
    
    setErrorExpired('Date range should be a minimum of 6 hours and a maximum of 7 days');
    setSharepublic(false)
  }

  setSelectedDate(e.target.value);
};

const Checklimit =() =>{
  if(downloadLimit === 0  || downloadLimit < 5 || downloadLimit >100 || downloadLimit.length === 0){
    setSharepublic(false)
  }
  else{
    setSharepublic(true)
  }
}
const CheckExpiredTime =() =>{
  if(errorExpired.length !== 0){
    setSharepublic(false)
  }
  else{
    setSharepublic(true)
  }
}
const handleHoursChange = (event) => {
  const inputHours = event.target.value;
  setHours(inputHours);
  if (inputHours.includes('-') || inputHours > 24 || inputHours.includes('.') || inputHours === '-') {
    setErrorExpired("Error: Value cannot contain both '-' and '.' and must be less than or equal to 24 hours");
  } else if (days === '7') {
    setErrorExpired(inputHours > 0 ? "The maximum data link expiry is 7 days" : "");
    setSharepublic(inputHours > 0 ? false: true)
  } else {
    if (inputHours <= 0 || inputHours === "") {
      if (days > 0) {
        setErrorExpired('');
        Checklimit()
      } else {
        setErrorExpired('Please specify the number of days');
        setSharepublic(false)
      }
    } else if (inputHours < 6 && days <= 0) {
      setErrorExpired('Hours must be at least 6 hours');
      setSharepublic(false)
    } else {
      setErrorExpired('');
      Checklimit()
    }
  }
};


  const viewPassword = () => {
    var passwordInput = document.getElementById("password-field");
    var passStatus = document.getElementById("pass-status");

    if (passwordInput.type == "password") {
      passwordInput.type = "text";
      passStatus.className = "fa fa-eye-slash";
    } else {
      passwordInput.type = "password";
      passStatus.className = "fa fa-eye";
    }
  };

  var myNodelist = document.getElementsByTagName("LI");
  var i;
  var emailsArray = [];
  var dropdownArray = [];

  var close = document.getElementsByClassName("close");

  const newElement = () => {
    var inputValue = document.getElementById("email-input").value;
    var selectedOption = document.querySelector(
      ".dropdown .dropdown-item.active"
    ).innerText;

    if (inputValue === "") {
      alert("You must write something!");
    } else {
      var li = document.createElement("li");
      var t = document.createTextNode(inputValue);
      li.appendChild(t);
      document.getElementById("email-list").appendChild(li);

      var span = document.createElement("SPAN");
      var txt = document.createTextNode("\u00D7");
      span.className = "close";
      span.appendChild(txt);
      li.appendChild(span);

      emailsArray.push(inputValue + " (" + selectedOption + ")");
      dropdownArray.push(selectedOption);

      document.getElementById("email-input").value = "";
    }

    for (i = 0; i < close.length; i++) {
      close[i].onclick = function () {
        var div = this.parentElement;
        div.style.display = "none";
      };
    }

    console.log(emailsArray);
    console.log(dropdownArray);
  };

  // if (emailsArray.length === 0) {
  //   console.log("Emails array is empty. Code will not execute.");
  // } else {
  //   console.log(emailsArray);
  //   console.log(dropdownArray);
  // }




const Editable =() =>{
 console.log(FileStatus)
 SetFileStatus(!FileStatus);

 
}


// remove email from existing list
const removeEmail = (emailToRemove) => {
 const updatedList = emailList.filter((email) => email !== emailToRemove);
 setEmailList(updatedList);
}; 


//email suggestion
const OnSearchEmail = (SearchTerm)=>{
 setEmail(SearchTerm)
 // SetSearchingemail(false)
 console.log("search",SearchTerm)

}



const clearpublicShareInfo =() =>{
const checkbox = document.getElementById("switch-check");
setZipPass(null)
setDownloadLimit(5)
setHours(6)
setDays(0)
setAccesCode(false)
if (checkbox) {
  checkbox.checked = false; // Turn off the checkbox
}
setEmailList([])
}

 // function handleGenerateColor() {
 //   setRandomColor(getRandomColor());
 // }
 const shareLink =() =>{
  clearpublicShareInfo()
  setCopyBtnColor(false)
  setCreatingLink(true)
 console.log("selected file***" ,SelectedFile)
 const requestOptions = {
   method: 'POST',
   headers: { 'Content-Type': 'application/json' },
   body: JSON.stringify({ 'email': UserEmail  , 'fileList':SelectedFile } ) 
};

fetch('https://helibucket.co.in/apis/share-public-link/', requestOptions)
   .then(response => response.json())
   .then(data => {
       console.log('=====>',data);
       setShareLinkUrl(data)
       setShareLink(true);
       setCreatingLink(false)
       clearpublicShareInfo()
       
});

 }
const closeBtn =()=>{
 if (sharLinkActive === true) {
   setShareLink(false);
 }
let fileLock = document.getElementById('file-Lock')
fileLock.classList.contains("text-success") ? fileLock.classList.remove("text-success") : console.log("have no password of zip") ;
 setShareLinkUrl("");
 setCopyBtnColor(false);
 setCopyBtnTxt('copy');
 setEmailList([])
 setEmail('');
 closeLinkData();
 if (SecretSharLinkActive === true ) {
   setSecretShareLink(false)
 }


}
const copyToBoard = () =>{
 var copyText = document.getElementById("copyLnk");
 copyText.select();
 copyText.setSelectionRange(0, 99999);
 console.log(copyText.value)
 //navigator.clipboard.writeText(copyText.value);
 copy(copyText.value);
 //alert("Copied the text: " + copyText.value);
 setCopyBtnColor(true);
 setCopyBtnTxt('Copied');
}

const setEmailValue = (e) =>{
 const email_id = e.target.value;
 setEmail(email_id);
 // SetSearchingemail(false)
 const suggestionbox = document.getElementById("suggestion_box1");
 // elements.style.borderRadius = '25px 25px 0 0';
 if (email_id.length == 0){
     suggestionbox.classList.add("d-none");
     // elements.style.borderRadius = '25px';
 }
 suggestionbox.classList.remove("d-none");
 // suggestionbox.classList.add("d-block")
}
const  validateEmail =(email)=> {
 const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
 return emailRegex.test(email);
}

//---------------------> for file zip password---------------------------------
const handleIconClick = () => {
 setShowInput(!showInput);
};

const SetZipPassValue = (e) => {
  // setZipPass(e.target.value.trim() === "" ? null :e.target.value );
 if (!/^\S*$/.test(e.target.value)) {
  // The input contains at least one space.
  // You can handle this case as needed.
} else {
  // The input does not contain any spaces. You can use it.
  setZipPass(e.target.value);
}

};


// const FileZipPass =() =>{
//  let fileLock = document.getElementById('file-Lock')
//  if (zipPasswordValue.length === 0 ){
//    Swal.fire({
//      title: 'You are not add password',
//      icon: 'error',
//      confirmButtonText: 'OK'
//      });
//      fileLock.classList.contains("text-success") ? fileLock.classList.remove("text-success") : console.log("have no password of zip") ;
//  } 
//  else{
   
//    setZipPass(zipPasswordValue)
//    setShowInput(!showInput);
//    fileLock.classList.add("text-success");
//  }
// }




//----------------------------------------zip password end---------------------------------------

//--------------------for file download limit  in public share---------------
const SetdownloadLimitValue = (e) => {
  setDownloadLimit(e.target.value);
  setLimitError("limit not blank or less than 5 or greater than 100")
  setSharepublic(e.target.value.length === 0  || e.target.value < 5 || e.target.value >100 || timeExpirederror === false ? false : true)
  setLimitError(e.target.value.length === 0 || e.target.value < 5 || e.target.value > 100 ? "Limit not blank or less than 5 or greater than 100" : "");
  CheckExpiredTime()
};

//--------------------for file Link expired in public share---------------
const SetExpiredTimeValue = (e) => {
  setLinkExpired(e.target.value);
 };

/// get file by access code
const handelAccessCode =(e) =>{
  setAccesCode(e.target.checked);
  console.log(accessCode)
}


const add = () => {
 console.log(email)
 if (email.length === 0 || validateEmail(email) === false  ){
   Swal.fire({
     title: 'You are not added any valid email',
     icon: 'error',
     confirmButtonText: 'OK'
     });
 } 
 else if (emailList.includes(email)){
   Swal.fire({
     title: 'You already add this email',
     icon: 'error',
     confirmButtonText: 'OK'
     });
 }
else{
 setEmailList(prevEmailList => [...prevEmailList, email])
 setEmail('')
}
 
 console.log(emailList)
   

};
const shareByEmail =() =>{

 if (emailList.length === 0){
   Swal.fire({
     title: 'You are not added any email',
     icon: 'error',
     confirmButtonText: 'OK'
     });
     setShareLink(false);
     setShareLinkUrl("");
     setEmailList([]);
     setEmail('');
     // navigate('/dashboard-premium')
     StorageType=== "premimum"? navigate('/dashboard-premium'):navigate('/dashboard');
     DeSelectAll()
 }
 else{
   
   setLoading(true)
   const requestOptions = {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({  "username": UserEmail, "file_list":SelectedFile, "receiver_username":emailList ,"zip_password" :zipPass , "expire_day" :days ,
      "expire_hours" :hours,
      "download_limit" : downloadLimit ,"otp_authentic":accessCode ,
      //  "time_zone":userTimeZone
       })
     };
     fetch('https://helibucket.co.in/apis/public-link-new/', requestOptions)
         .then(response => response.json())
         .then(data => {
           if(data.msg==='ok'){
             setLoading(false)
             Swal.fire({
               title: 'email Send successfully',
               icon: 'success',
               confirmButtonText: 'OK'
               });
               setShareLink(false);
               setShareLinkUrl("");
               setEmailList([]);
               setEmail('');
               clearpublicShareInfo()
               // navigate('/dashboard-premium/')
               StorageType=== "premimum"? navigate('/dashboard-premium/'):navigate('/dashboard/');
               
           }
         });
 }
 
}
const shareSercretLink =() =>{
 setSecretShareLink(true)
}
const handelSenderEmail =(e)=>{
const SenderEmail = e.target.value;
setSenderEmail(SenderEmail)
}
const handelReciverEmail =(e)=>{
const ReciverEmail = e.target.value;
setReciverEmail(ReciverEmail)
}



const sharePrivately =() =>{
 if (emailList.length === 0){
   Swal.fire({
     title: 'You are not added any email',
     icon: 'error',
     confirmButtonText: 'OK'
     });
     setShareLink(false);
     setShareLinkUrl("");
     setEmailList([]);
     setEmail('');
     // navigate('/dashboard-premium/')
     StorageType=== "premimum"? navigate('/dashboard-premium/'):navigate('/dashboard/');
 }
 else{
   const SelectedFileWithStatus =[]
   for (let i = 0; i < SelectedFile.length; i++) {
     SelectedFileWithStatus.push({"filename":SelectedFile[i],"edit": FileStatus});

   }
   setLoading(true)
   const requestOptions = {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({ email: UserEmail, fileList:SelectedFileWithStatus, shareEmail:emailList , firstCommentMsg :firstCommentMsg })
     };
     fetch('https://helibucket.co.in/apis/share-private-link/', requestOptions)
         .then(response => response.json())
         .then(data => {
           setLoading(false)
           console.log("the reflected data is" , data)
           if(data==='sent'){
             Swal.fire({
               title: 'email Send successfully',
               icon: 'success',
               confirmButtonText: 'OK'
               });
               setShareLink(false);
               setShareLinkUrl("");
               setEmailList([]);
               setEmail('');
               clearpublicShareInfo()
               setFirstCommentMessge("")
               // navigate('/dashboard-premium/')
               StorageType=== "premimum"? navigate('/dashboard-premium/'):navigate('/dashboard/');
               setSecretShareLink(false)
           }
         });
   // sendEmailAndSharePrivately()
     }



}
const CloseBtn =() =>{
clearpublicShareInfo()
setSharepublic(true)
setZipError('')
setLinkExpired("")
setErrorExpired('');
setExpiredError(true)
setAccesCode(false)
setLimitError('');
const fileProtection = document.getElementById("file-protection")
const uploadLimit = document.getElementById("upload-limit")
const sheduleTransfer = document.getElementById("shedule-transfer")
const expireTime = document.getElementById("expire-time")
const FileProtectionTab = document.getElementById("file-protection-tab")
const uploadLimitTab =document.getElementById("upload-limit-tab")
const ExpireTimetab =document.getElementById("expire-time-tab")

if (FileProtectionTab.classList.contains("active")) {
  FileProtectionTab.classList.remove("active");
}
if (uploadLimitTab.classList.contains("active")) {
  uploadLimitTab.classList.remove("active");
}
if (ExpireTimetab.classList.contains("active")) {
  ExpireTimetab.classList.remove("active");
}

if (fileProtection.classList.contains("active")) {
  fileProtection.classList.remove("active");
}
if (fileProtection.classList.contains("show")) {
  fileProtection.classList.remove("show");
}
if (uploadLimit.classList.contains("active")) {
  uploadLimit.classList.remove("active");
}
if (uploadLimit.classList.contains("show")) {
  uploadLimit.classList.remove("show");
}
if (sheduleTransfer.classList.contains("active")) {
  sheduleTransfer.classList.remove("active");
}
if (sheduleTransfer.classList.contains("show")) {
  sheduleTransfer.classList.remove("show");
}
if (expireTime.classList.contains("active")) {
  expireTime.classList.remove("active");
}
if (expireTime.classList.contains("show")) {
  expireTime.classList.remove("show");
}
closeLinkData();
if (SecretSharLinkActive === true ) {
  setSecretShareLink(false)
}
}

  return (
    <div
        className="modal fade "id="ModalShare" data-bs-backdrop="static"  data-bs-keyboard="false"  tabindex="-1" aria-labelledby="ModalShareLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header share-info-header"  >
              <p className="modal-title" id="exampleModalLabel" style={{ color: "#8A8E9C", fontFamily: "Inter", fontSize: 15, fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }}>
                Share Your Files
              </p>
              <button type="button" onClick={CloseBtn} data-bs-dismiss="modal" style={{ border: "none", backgroundColor: "transparent" }} >
                <i
                  className="bi fs-5 bi-x-circle-fill"
                  style={{background:
                      "var(--Linear, linear-gradient(0deg, #000258 0%, #0053B4 100%))",backgroundClip: "text",WebkitBackgroundClip: "text",WebkitTextFillColor: "transparent",
                  }}
                />
              </button>
            </div>
            <div className="share-info-body">
                <nav>
                    <div class="nav nav-pills nav-fill share-tab" id="nav-tab" role="tablist">
                      <a class="nav-link active h2-heading" id="step1-tab" data-bs-toggle="tab" href="#step1" >Private Share</a>
                      <a class="nav-link h2-heading" id="step2-tab" data-bs-toggle="tab" href="#step2" >Public Share with email</a>
                      <a class="nav-link h2-heading" id="step3-tab" data-bs-toggle="tab" href="#step3" onClick={shareLink} >Public Share</a>
                    </div>
                </nav>
              <div class="tab-content">
                <div class="tab-pane fade show active"  data-aos="fade-right"  data-aos-offset={500}  data-aos-easing="ease-in-sine" id="step1" >
                  <div className={`card card-body cards-body ${loading ? 'd-block'  : "d-none" } `}>
                      <div className='container d-flex align-items-center justify-content-center'>
                        <img src={Loader}/>
                      </div>
                      {/* <CollaborationInfo collaboration = {collaboration} CollaboerationLoader = {CollaboerationLoader}  /> */}
                  </div> 
                  <div className={`card card-body cards-body ${loading ? 'd-none'  : "" }  `}>
                    <div className="d-flex">
                      <div className="email-drop " id="email-form">
                        <input type="email" placeholder="add email id" className="email-cont"  autoComplete='new-password' onChange={setEmailValue} value={email} />
                        
                        {/* <div class="dropdown mt-2 permission-drop-down">
                            <a className="view_style_button dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span><i class="bi bi-binoculars"></i> View </span>
                                    {FileStatus === true ? (<span> , <i className="bi bi-pencil-square"></i> Edit </span> ):(null) }
                                    <i className="bi bi-chevron-down"></i>
                            </a>
                            <ul className="dropdown-menu  dropDown_Item" aria-labelledby="dropdownMenuLink">
                              <li  id="view" ><a className="dropdown-item d-flex input-group row drop-downItem" href="#"><div className='col-1 me-1'><i class="bi bi-check2"></i></div><div className='col-9 d-inline-block'>View</div></a></li> 
                              <li onClick={ Editable } id ="edit" ><a className="dropdown-item d-flex input-group row drop-downItem" href="#">{FileStatus === true ? (<div className='col-1 me-1'><span><i class="bi bi-check2"></i></span></div>) : (<div className='col-1 me-1'></div>)} <div className='col-9 d-block'>Edit</div> </a></li>
                            </ul>
                        </div>  */}
                      </div>
                      &nbsp; &nbsp;
                      <button className="eml-btn" id="add-button" onClick={()=>add()}>
                        Add Email
                      </button>
                    </div>
                      <div className={`col-12 dropdown shadow bg-body-tertiary rounded`} id="suggestion_box1" style={{"overflowY": "auto",width:"470px",marginLeft:"18px", marginTop:"-3px"}}>
                        <div className='dropdown-row'>
                          {email_list.filter(item=>{
                            const EmailSearchTerms = email.toLowerCase();
                            const emaillist = item.toLowerCase();
                            return EmailSearchTerms && emaillist.startsWith(EmailSearchTerms) && emaillist !== EmailSearchTerms; ;
                          }).map((item, index) => (
                            <div className='input-group p-2 emailList' onClick={() => OnSearchEmail(item)} >
                              <button  style={{width: '50px',height: '50px',borderRadius:"27px",alignItems:"center",backgroundColor: "red",fontSize:"16px",color:"white",textTransform:"uppercase"}}>{email[0]}</button>
                              <div key={index} className='p-2 mt-2'>{item}</div>
                            </div>
                            ))}
                        </div>
                      </div>
                    <div className="scroll-in-it mt-3">
                      <ul id="email-list">
                      {emailList.map((email, index) => (
                      <li key={index} className='d-flex p-2' ><div className='w-100'>{email}&nbsp;<i class="bi bi-x-lg"onClick={() => removeEmail(email)}></i></div></li>
                    ))}
                    </ul>
                    </div>
                    <div className='d-flex text-box-div'>
                       <textarea className="form-control text-box" value={firstCommentMsg} onChange={e => setFirstCommentMessge(e.target.value)}  placeholder='Enter your message (optional)' id="exampleFormControlTextarea1" rows="3"></textarea>
                    </div>
                    <div class="dropdown mt-2 ">
                      <a className="view_style_button dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                              <span><i class="bi bi-binoculars"></i> View </span>
                              {FileStatus === true ? (<span> , <i className="bi bi-pencil-square"></i> Edit </span> ):(null) }
                              <i className="bi bi-chevron-down"></i>
                      </a>
                      <ul className="dropdown-menu  dropDown_Item" aria-labelledby="dropdownMenuLink">
                          <li  id="view" ><a className="dropdown-item d-flex input-group row drop-downItem" href="#"><div className='col-1'><i class="bi bi-check2"></i></div><div className='col-11'>View</div></a></li> 
                          <li onClick={ Editable } id ="edit" ><a className="dropdown-item d-flex input-group row drop-downItem" href="#">{FileStatus === true ? (<div className='col-1'><span><i class="bi bi-check2"></i></span></div>) : (<div className='col-1'></div>)} <div className='col-11'>Edit</div> </a></li>
                        </ul>
                    </div>                
                    <div className="text-center shr-btnn">
                      <button
                        style={{borderRadius: 74,background:
                            "var(--Linear, linear-gradient(0deg, #000258 0%, #0053B4 100%))",color: "#fff",padding: "10px 15px 10px 15px",border: "none",
                        }}
                        type='button'
                        onClick={sharePrivately}
                      >
                        <i className="bi bi-share-fill text-white" />
                        &nbsp; Share
                      </button>
                     
                    </div>
                    {/* <CollaborationInfo collaboration = {collaboration} CollaboerationLoader = {CollaboerationLoader}  /> */}
                  </div>
                
                </div>
                <div className="tab-pane fade aos-init aos-animate"  data-aos="fade-right"  data-aos-offset={500} data-aos-easing="ease-in-sine"  id="step2">
                <div className={`card card-body cards-body ${loading ? 'd-block'  : "d-none" } `}>
                    <div className='container d-flex align-items-center justify-content-center'>
                      <img src={Loader}/>
                    </div>
                    {/* <CollaborationInfo collaboration = {collaboration} CollaboerationLoader = {CollaboerationLoader}  /> */}
                </div> 
                  <div className={`card card-body cards-body  ${loading ? 'd-none' : ""}`}>
                    <div className="d-flex email-tab">
                        <input type="email" placeholder="add email id" onChange={setEmailValue} value={email} className="email-drop-2"/>
                        &nbsp;&nbsp;
                      <button className="emil-btn" onClick={()=>add()}>
                        Add Email
                      </button>
                    </div>
                    <div className="scroll-in-it mt-3">
                      <ul id="email-list">
                      {emailList.map((email, index) => (
                       <li key={index} className='d-flex p-2' ><div className='w-100'>{email}&nbsp;<i class="bi bi-x-lg"onClick={() => removeEmail(email)}></i></div></li>
                    ))}
                    </ul>
                    </div>
                    <div className=" row">       
                      <div className="col-12">
                          <nav className="d-flex align-content-center pt-3 justify-content-center">
                            <div className="nav nav-pills nav-fill fetures-tab" id="nav-tab" role="tablist">
                              <a className="public-link-feature nav-link"  id="file-protection-tab"   data-bs-toggle="tab" href="#file-protection"  style={{ borderRadius: "15px 0px 0px 15px" }}>
                                <i className="bi bi-file-earmark-lock icon-style-public-share"/>&nbsp;File Protection
                              </a>
                              <a className="public-link-feature nav-link" id="upload-limit-tab"   data-bs-toggle="tab" href="#upload-limit">
                                <i className="bi bi-cloud-download icon-style-public-share"/>&nbsp;Download Limit
                              </a>
                              {/* <a className="public-link-feature nav-link" id="shedule-transfer-tab"  data-bs-toggle="tab" href="#shedule-transfer">
                                  <i className="bi bi-calendar3 icon-style-public-share"/>&nbsp;Schedule Transfer
                              </a> */}
                              <a className="public-link-feature nav-link" id="expire-time-tab"  data-bs-toggle="tab" href="#expire-time" style={{
                                borderRadius: "0px 15px 15px 0px",}}>
                                  <i className="bi bi-clock-history icon-style-public-share"/>&nbsp;Link Expiry
                              </a>
                            </div>
                          </nav>
                      </div>
                      <div className="col-12">
                        <div className="tab-content d-flex align-content-center pt-3 justify-content-center">
                          <div className="tab-pane fade tab-border " id="file-protection">
                            <div className="box-msg">
                              <label for="start" id="in-1">Add password to secure your files:</label>
                              <br />
                              <input type="password" className='form-control' id="password-field" onChange={SetZipPassValue} value={zipPass} autocomplete="new-password"  placeholder="enter zip password"/>
                              {/* <i id="pass-status" className="fa fa-eye" aria-hidden="true" onClick={viewPassword}/> */}
                            </div>
                          </div>
                          <div className="tab-pane fade tab-border" id="upload-limit">
                              <div className="box-msg" >
                                <label htmlFor="quantity">Using this link file can be download upto</label>
                               <br/>
                                <input type="number" className={`form-control ${downloadLimit.length === 0 || downloadLimit < 5 || downloadLimit > 100 ? 'bg-danger': ""}`}  onChange={SetdownloadLimitValue} value={downloadLimit} /> 
                                &nbsp; times
                                {limiterror && <p className='text-danger' >{limiterror}</p>}
                              </div>
                          </div>
                          <div className="tab-pane fade tab-border" id="shedule-transfer">
                              <div className="box-msg">
                                <label htmlFor="meeting-time">Set your date and time:</label>
                                <input type="datetime-local" id="datetime-local" name="meeting-time"/>
                              </div>
                          </div>
                          <div className="tab-pane fade tab-border" id="expire-time">
                              <div className="box-msg">
                                <label htmlFor="start" id="in-1">
                                  Set expiry next day and hours :
                                </label>
                                <input type="datetime-local" id ="date" onChange={handleDateChange} value={selectedDate} className='form-control'/>
                                {/* <input type="number" id="days" className='form-control' value={days} onChange={handleDaysChange} />days
                                <input type="number" id="Hours" className='form-control' value={hours} onChange={handleHoursChange} /> hours */}
                                {errorExpired && <p className='text-danger' >{errorExpired}</p>}
                                <br/>
                                {/* <input type="date" onChange={SetExpiredTimeValue} value={linkExpiredTime} id="date" name="trip-start" /> */}
                              </div>
                          </div>
                        </div>  
                      </div>

                    </div>
                    <div className ="d-flex align-content-center justify-content-center" >
                      <i
                        className="bi bi-shield-lock-fill"
                        style={{background:
                            "var(--Linear, linear-gradient(0deg, #000258 0%, #0053B4 100%))",backgroundClip: "text",WebkitBackgroundClip: "text",WebkitTextFillColor: "transparent",
                        }}
                      />
                      &nbsp;Secure the download page with access code
                      &nbsp;
                      <div className="form-switch form-own-switch" >
                        <input className="form-check-input switch-check" onChange={handelAccessCode}  type="checkbox" role="switch" id="switch-check"  />
                      </div>
                    </div>
                    <div className={`text-center shr-btnn `}>
                      <button onClick={shareByEmail} id = "share" className={`share_public ${Sharepublic ? ""  : "disabled" }`} disabled={!Sharepublic} type='button'>
                        <i className="bi bi-share-fill text-white" />
                        &nbsp;Share
                      </button>
                    </div>
                    {/* <CollaborationInfo  collaboration = {collaboration} CollaboerationLoader = {CollaboerationLoader}  /> */}
                  </div>
                  
              </div>
              <div className="tab-pane fade aos-init aos-animate" data-aos="fade-right"  data-aos-offset={500} data-aos-easing="ease-in-sine" id="step3">
                <div className={`card card-body cards-body ${CreatingLink ? 'd-block'  : "d-none" } `}>
                    <div className='container d-flex align-items-center justify-content-center'>
                      <img src={Loader}/>
                    </div>
                    {/* <CollaborationInfo collaboration = {collaboration} CollaboerationLoader = {CollaboerationLoader} /> */}
                </div> 
                <div className={`card card-body cards-body ${CreatingLink   ? "d-none"  : null } `}>
                  <div className="d-flex">
                    <div className="email-drop-3">
                      <input type="text" className="email-cont-2 ml-2" id="copyLnk" readonly="" value={shareLinkUrl}  />
                      {/* <div class="text-center shr-btnn"> */}
                      <button onClick={copyToBoard} className={copyBtnColor?'copy-btn-color-green':'copy-btn-color-blue'}>
                        <i className="bi bi-link-45deg" />
                        &nbsp;Copy Link
                      </button>
                      {/* </div> */}
                    </div>
                  </div>
                  <ShareButtons shareLinkUrl ={shareLinkUrl} />
                  {/* <div className='pt-3'>
                    <CollaborationInfo collaboration = {collaboration} CollaboerationLoader = {CollaboerationLoader}  />
                  </div> */}
                  
                </div>   
                
              </div>
                    
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}







