import React, { useState } from 'react'
import Swal from 'sweetalert2';
import {useNavigate} from "react-router-dom"
import '../assets/Styles/login.css'
import { Link } from "react-router-dom";
export default function SignUp() {
    const [name , setname ] = useState("")
    const [email , setEmail] = useState("")
    const [password , setPassword] = useState("")
    const [repeatpass , setRepPass] = useState("")
    const [registerLoader , setRegisterLoader] = useState(false)
    const navigate = useNavigate();
    const addNameData = (e) =>{
        // const name = e.target.name;
		const namevalue = e.target.value;
        // if (namevalue.length <3){
        //     name.style.css = 
        // }
        
        setname(namevalue)	}
    const addEmailData = (e) =>{
        const emailvalue = e.target.value;
        setEmail(emailvalue)	}

    const addPasswordData=(e)=>{
    const pass_word = e.target.value;
    setPassword(pass_word);
    }
    const addRepPassData=(e)=>{
    const repPass_word = e.target.value;
    setRepPass(repPass_word);
    }


const registerData =(e) =>{
  setRegisterLoader(true)
    e.preventDefault();
    if (email ==="" || name==="" ||  password ==="" || repeatpass==="" ){
		Swal.fire({
			title: 'email or password null!',
			icon: 'error',
			confirmButtonText: 'OK'
		  });
      setRegisterLoader(false)
		  navigate('/register');

	}
    else if (password ===repeatpass ){
    const URL='https://heliware.co.in/api/users/';
	  const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email: email, password: password, username: name})
    };
    fetch(URL, requestOptions)
		.then(response => response.json())
		.then(data => {
            if (data['message']){
                if(data["message"]==='User already exists'){
                Swal.fire({
                    title: 'User already exists!',
                    icon: 'error',
                    confirmButtonText: 'OK'
                  });
                  setRegisterLoader(false)
                  navigate('/register');
                }
                else if(data["message"]==='Some Internal Error Occurred'){
                    Swal.fire({
                        title: 'An Internal Error Occurred!',
                        icon: 'error',
                        confirmButtonText: 'OK'
                      });
                      setRegisterLoader(false)
                      navigate('/register');
                }
              
            }else{
            //   setRegsuccess(true);
            //   setUserexists(false);
            Swal.fire({
                title: 'Register Successfully!',
                text: 'Chek Your Mail to Activate Your Account!',
                icon: 'success',
                confirmButtonText: 'OK'
              });
              setRegisterLoader(false)
            setTimeout(navigate('/login'), 5000);
                
            }
        });
    }
    else{
        Swal.fire({
			title: 'Password not matched!',
			icon: 'warning',
			confirmButtonText: 'OK'
		  });
      setRegisterLoader(false)
		  navigate('/register');
    }

}
  return (
    <>
    <div className="main-mobile" id="register">
        <div className="text-center">
            <form className="form" id="a-form" method = "post" onSubmit={registerData} action>
            <h2 className="form_title title mt-4">Create Account</h2>
            <div className="form__icons" />
            <input className="form__input mt-4" required type="text" onChange={addNameData}  value = {name} placeholder="Name" />
            <input className="form__input mt-4" required type="email" onChange={addEmailData} value = {email} placeholder="Email" />
            <input className="form__input mt-4" required type="password"onChange={addPasswordData}  value = {password} placeholder="Password" />
            <input className="form__input mt-4" required type="password" onChange={addRepPassData} value ={repeatpass} placeholder="Repeat password" />
            <button className="form__button button">SIGN UP<span class={`spinner-border spinner-border-sm ${registerLoader ? null:'d-none' } `} role="status" aria-hidden="true"></span></button>
            <p className="mt-3 mb-3">Already have an account?<Link to="/login" >LogIn</Link></p>

            </form>
        </div>
    </div>
    </>
  )
}
