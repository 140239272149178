import React from 'react'
import swal from 'sweetalert';
import {
    useParams, useNavigate
    
  } from 'react-router-dom';
import logo from '../images/logo.png';
import NewHeader from '../components/HeaderNew';
import FooterNew from '../components/FooterNew';
import '../assets/Styles/login.css'
export default function Contact() {
    const [Fullname, setFullName] = React.useState('');
    const [EmailId, setEmailId] = React.useState('');
    const [PhoneNo, setPhoneNo] = React.useState('');
    const [Message, setMessage] = React.useState('');
    const [SuccessMsg,setSuccessMsg] = React.useState(false);
    const navigate = useNavigate();

    const handleFullNameIp = (e) =>{
        const fullname = e.target.value;
        setFullName(fullname);
    }
    const handleEmailIp = (e) =>{
        const email = e.target.value;
        setEmailId(email);
    }
    const handlePhoneIp = (e) =>{
        const phone = e.target.value;
        setPhoneNo(phone);
    }
    const handleMessage = (e) =>{
        const msg = e.target.value;
        setMessage(msg);
    }
    const feedBackSend = () =>{
        if(Fullname!=='' && EmailId!=='' && PhoneNo !=='' && Message !==''){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ your_name:Fullname, your_email:EmailId, your_mob:PhoneNo, your_msg:Message })
                };
                fetch('https://helibucket.heliware.co.in/apis/feedback-form', requestOptions) 
                    .then(response => response.json())
                    .then(data => setSuccessMsg(true));
        }else{
            swal("Oh..No!", "Please fill all the field.", "warning");
        }
    }
  return (
    <div className=''>
        <div className='about-us-hero'>
            {/* <div className='container-fluid my-desktop-menu'>
                <div className='row navbar-sec'>
                    <div className='col-xxl-8 col-xl-7 col-lg-7'><a href='/' ><img alt='file share website, send large files' className='logo-img' src={logo} /></a></div>
                    <div className='col-xxl-4 col-xl-5 col-lg-5'>
                        <ul className="nav justify-content-end nav-sec-nav">
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" aria-current="page" onClick={()=>navigate("/")} href="#">Home</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" aria-current="page" onClick={()=>navigate("/upload/")} href="#">Upload</button>
                            </li>
                            
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" onClick={()=>navigate("/about")} href="#">About Us</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c"  onClick={()=>navigate("/register")}>Sign Up</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" onClick={()=>navigate("/login")} >Sign In</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='container-fluid my-mobile-menu'>
                <div className='row navbar-sec'>
                    <div className='col-lg-9 col-6 col-sm col-md '><a href='#' onClick={()=>navigate("/")}><img className='logo-img mobile-logo' src={logo} /></a></div>
                    <div className='col-lg-3 col-6 col-sm col-md '>
                        <div className='text-end'>
                        <button className="btn-invisible-bg my-mobile-menu " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <i className="bi bi-list text-white bar-ico-menu"></i>
                        </button>
                        </div>
                    
                    </div>
                </div>
            </div>
            
            <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel"><a href='/' ><img alt='file share, send large files free throught helibucket'  className='logo-img-mob-menu' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1669208333/logo_1_ny5kuf_1_hcguue.png' /></a></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <ul className="dropdown-menu-v">
                            <li><a href='/' className="dropdown-item" onClick={()=>navigate("/")} >Home</a></li>
                            <li><a href='/upload/' className="dropdown-item mt-3" onClick={()=>navigate("/upload/")} >Upload</a></li>
                            <li><a href='/about/' className="dropdown-item mt-3" onClick={()=>navigate("/about/")} >About</a></li>
                            <li><a href='/contact/' className="dropdown-item mt-3 " onClick={()=>navigate("/contact/")} >Contact Us</a></li>
                            <li><a href='/register' className="dropdown-item mt-3 "  >Sign Up</a></li>
                            <li><a href='/login' className="dropdown-item mt-3 "  >Sign In</a></li>
                        </ul>
                    </div>
                    <div className='mt-5 text-center'>
                        <button className='rounded-pill btn-mob-menu'>Download Desktop Version</button>
                    </div>
                    <div className='mt-5 text-center'>
                        <div className='mob-menu-social text-center'>
                            <div className='me-3'>
                            <a className='me-3 color-aaa' href='https://www.facebook.com/HeliBucket'><i className="bi bi-facebook fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://instagram.com/heli_bucket'><i className="bi bi-instagram fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://www.linkedin.com/showcase/helibucket/'><i className="bi bi-linkedin fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://twitter.com/heli_bucket'><i className="bi bi-twitter fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://youtube.com/channel/UCykpS0MUzwhdcmRmWpY2uLQ'><i className="bi bi-youtube fs-3"></i></a>
                            </div>
                        
                        </div>
                    </div>
                    
                    
                </div>
            </div> */}
            <NewHeader/>
            <div className='container'>
                <div className='row'>
                    <p className='hero-heading-about mt-5 mb-5'>Contact Us</p>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row mt-5 mb-5'>
                <div className='col-12 col-lg-6'>
                    <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.7085565023085!2d77.05888277537449!3d28.458200675760295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d196d7134feff%3A0x9303cd10e7192829!2sHeliware!5e0!3m2!1sen!2sin!4v1669396329945!5m2!1sen!2sin" className='contact-map'  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div className='col-12 col-lg-6'>
                    
                    <div className='mt-3'>
                        <div>
                            <input type='text' onChange={handleFullNameIp} placeholder='Full Name.' value={Fullname} className='form-control mt-3 f contact-form' />
                        </div>
                        <div> 
                            <input type='text' onChange={handleEmailIp} placeholder='Email Address.' value={EmailId} className='form-control mt-3  contact-form' />
                        </div>
                        <div>
                            <input type='text' onChange={handlePhoneIp} placeholder='Phone Number.' value={PhoneNo} className='form-control  mt-3  contact-form' />
                        </div>
                        <div>
                            <textarea placeholder='Message.' onChange={handleMessage} value={Message} className='form-control form-control-lg mt-3 contact-form' rows="3" contact-form></textarea>
                        </div>
                        <div>
                            <button onClick={feedBackSend} className='w-100 btn btn-primary submitbutton mt-3'style={{"borderRadius":"7px"}}>Submit</button>
                        </div>
                        <div className={SuccessMsg ? null : 'd-none'}>
                            <div className='mt-3'>
                                <div className='bg-success success-msg-div p-2 ps-3 text-white'>
                                    Thank you! Your message sent Successfully..
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid mt-3 mb-5' id='contact-us'>
            
            
            {/* <div className='row'>
                <div className='col-lg-3'>
                    <div className='text-center mt-3'>
                        <span className='icon-frame'><i className=" icon-c bi bi-envelope-open"></i></span>
                        <p className='contact-text  mt-3'>contact@heliware.co.in</p>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='text-center mt-3'>
                        <span className='icon-frame'><i className=" icon-c bi bi-geo-alt-fill"></i></span>
                        <p className='contact-text mt-3'>24 Square, South city-1, Sector-41, Gurugram, Haryana 122022 </p>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div className='text-center mt-3'>
                        <span className='icon-frame'><i className=" icon-c bi bi-telephone-fill"></i></span>
                        <p className='contact-text mt-3'>+918860276440</p>
                    </div>
                </div>
            </div> */}
        </div>

        <div className="modal fade" id="exampleModalforpop" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
        <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
            <div className="text-center mb-5 mt-3">
            <p className="fs-3 text-center fw-bold"> Our Browser Version Coming Soon </p>
            <img alt='big data sharing platform helibucket' src="https://res.cloudinary.com/dgx4bwfhd/image/upload/v1667815210/New_Project_2_1_o43jrr.png" className="pc-img mt-3"></img>
            <p className="fs-5 text-center mt-3"> Download our desktop version<br/> & get 25 GB free cloud storage </p>
            <button className="btn btn-primary rounded-pill mt-3 pe-5 ps-5" onClick={()=>window.location.href='https://heliwarestorage.s3.us-west-2.amazonaws.com/bucket_data/heli_bucket/HeliBucket_Installer-0.1.exe'}>Download Now</button>
            </div>
        </div>
        
        </div>
    </div>
    </div>
    <FooterNew/>

        {/* <div className='footer-heli'>
            <div className='container'>
                <div className='row'>
                    
                    <div className='col-12 col-sm-12 col-md-6 mt-1 t-n-c-f'>
                        <a className='footer-text me-2' href='/terms-and-conditions/'>Terms & Condition</a>
                        <a className='footer-text me-2' href='/use-policy/'>Use Policy</a>
                        <a className='footer-text me-2' href='/return-policy/'>Refund Policy</a>
                        <a className='footer-text me-2' href='/contact/'>Help & Support</a>
                    </div>
                
                    <div className='col-12 col-sm-12 col-md-6'>
                        <p className=' color-aaa copyright-text'>
                        Copyright © 2023 Helibucket.co.in. All Rights Reserved.
                        </p>
                    </div>
                    
                </div>
            </div>
        </div> */}
        
        
    </div>
  )
}
