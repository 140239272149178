import React from 'react'
import {
    useParams, useNavigate
    
  } from 'react-router-dom';
import logo from '../images/logo.png';
import NewHeader from '../components/HeaderNew';
import FooterNew from '../components/FooterNew';
export default function About() {
    const navigate = useNavigate();
  return (
    <div className=''>
        <NewHeader/>
        <div className='about-us-hero'>
            {/* <div className='container-fluid my-desktop-menu'>
                <div className='row navbar-sec'>
                    <div className='col-xxl-8 col-xl-7 col-lg-7'><a href='/' ><img alt='file share website, send large files' className='logo-img' src={logo} /></a></div>
                    <div className='col-xxl-4 col-xl-5 col-lg-5'>
                        <ul className="nav justify-content-end nav-sec-nav">
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" aria-current="page" onClick={()=>navigate("/")} href="#">Home</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" aria-current="page" onClick={()=>navigate("/upload/")} href="#">Upload</button>
                            </li>
                            
                            <li className="nav-item">
                                <button className="nav-link nav-link-c" onClick={()=>navigate("/contact")} href="#">Contact Us</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c"  onClick={()=>navigate("/register")}>Sign Up</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link nav-link-c"  onClick={()=>navigate("/login")}>Sign In</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='container-fluid my-mobile-menu'>
                <div className='row navbar-sec'>
                    <div className='col-lg-9 col-6 col-sm col-md '><a href='#' onClick={()=>navigate("/")}><img className='logo-img mobile-logo' src={logo} /></a></div>
                    <div className='col-lg-3 col-6 col-sm col-md '>
                        <div className='text-end'>
                        <button className="btn-invisible-bg my-mobile-menu " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <i className="bi bi-list text-white bar-ico-menu"></i>
                        </button>
                        </div>
                    
                    </div>
                </div>
            </div>
            
            <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel"><a href='#' onClick={()=>navigate("/")}><img className='logo-img-mob-menu' alt='file share, send large files free throught helibucket' src='https://res.cloudinary.com/dgx4bwfhd/image/upload/v1669208333/logo_1_ny5kuf_1_hcguue.png' /></a></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <ul className="dropdown-menu-v">
                            <li><a href='/' className="dropdown-item" onClick={()=>navigate("/")} >Home</a></li>
                            <li><a href='/upload/' className="dropdown-item mt-3" onClick={()=>navigate("/upload/")} >Upload</a></li>
                            <li><a href='/about/' className="dropdown-item mt-3" onClick={()=>navigate("/about/")} >About</a></li>
                            <li><a href='/contact/' className="dropdown-item mt-3 " onClick={()=>navigate("/contact/")} >Contact Us</a></li>
                            <li><a href='/register' className="dropdown-item mt-3 "  >Sign Up</a></li>
                            <li><a href='/login' className="dropdown-item mt-3 "  >Sign In</a></li>
                        </ul>
                    </div>
                    <div className='mt-5 text-center'>
                        <button className='rounded-pill btn-mob-menu'>Download Desktop Version</button>
                    </div>
                    <div className='mt-5 text-center'>
                        <div className='mob-menu-social text-center'>
                            <div className='me-3'>
                            <a className='me-3 color-aaa' href='https://www.facebook.com/HeliBucket'><i className="bi bi-facebook fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://instagram.com/heli_bucket'><i className="bi bi-instagram fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://www.linkedin.com/showcase/helibucket/'><i className="bi bi-linkedin fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://twitter.com/heli_bucket'><i className="bi bi-twitter fs-3"></i></a>
                            <a className='me-3 color-aaa' href='https://youtube.com/channel/UCykpS0MUzwhdcmRmWpY2uLQ'><i className="bi bi-youtube fs-3"></i></a>
                            </div>
                        
                        </div>
                    </div>
                    
                    
                </div>
            </div> */}
            <div className='container'>
                <div className='row'>
                    <p className='hero-heading-about mt-5 mb-5'>About Us</p>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col'>
                    <div className='what-is-helibucket mt-5'>
                        <p className='what-is-helibucket-heading'>About <span style={{'color':'#4285f4'}}>Heli Bucket</span></p>
                        <p className='what-is-helibucket-des mt-3 mb-3'>
                        Heli Bucket is a file storage and synchronization service developed by Heliware (Parent Org: SGR Labs Private Limited). It allows users to store files in the cloud, synchronize files across devices, and share files. 
HeliBucket provides faster synchronization of data across various
platforms through our user-friendly interface which comes with multiple
data security.

                        </p>
                        <p className='what-is-helibucket-heading'>Heli Bucket <span style={{'color':'#4285f4'}}>Instant Transfer</span></p>
                        <p className='what-is-helibucket-des mt-3'>
                        Heli Bucket’s web-version, allows users to share their files from any internet-connected computer, without the need to download an app.The website offers instant transfer to their users, in which they can transfer up to 5 GB of data without any sign up needed. This feature comes in handy when you want to transfer large files up to 5 GB instantaneously, without giving any details. Just instant transfer without any hassle

                        </p>
                    </div>
                    <div className='what-is-helibucket mt-5 mb-5'>
                        <p className='what-is-helibucket-heading'>Heli Bucket <span style={{'color':'#4285f4'}}>Desktop Version</span></p>
                        <p className='what-is-helibucket-des mt-3 mb-3'>
                        In addition to a web interface, Heli Bucket offers a desktop version for Windows. 
The desktop version provides their users to store and share up to 25 GB of data completely free. 
It also allows users to share their data according to their needs. For that Heli Bucket provides 3 modes of sharing-

                        </p>
                        <ul>
                            <li><b>Private by email:</b> To share an individual</li>
                            <li><b>Data link:</b> To generate public link.</li>
                            <li><b>Send email transfer:</b> To share a group of people after OTP verification</li>
                        </ul>
                        <p className='what-is-helibucket-heading mt-3'>With <span style={{'color':'#4285f4'}}>Heli Bucket</span> You Can</p>
                        <p className='what-is-helibucket-des mt-3'>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                        </p>
                        <ul>
                            <li><b>  Manage, Store and Process your data in Geo-Spatial Way:</b> HeliBucket offers cloud storage solutions to meet the needs of both businesses and corporate professionals. Users have the option of purchasing storage from us or sharing a set storage size connection with another user.</li>
                            <li><b>  Send data privately via OTP or email:</b> It’s a simple-to-use file-transferring feature that allows sending files without any hassle.</li>
                            <li><b>  Free Instant Transfer without hassle:</b> Users are allowed to upload 5GB of data at a time, completely free of charge and without any verification. The user can upload up to 25 GB of data at a time if they log in.</li>
                            <li><b>  Faster Synchronization, User friendly and Secured data:</b> HeliBucket provides faster synchronization of data across various platforms through our user-friendly interface which comes with multiple data security.</li>
                            <li><b>  Better productivity from your Desktop:</b> You may collaborate with your team, content, and tools using our desktop experience, all from the ease of one well-organized location.</li>
                            <li><b>  Send data privately via OTP or email:</b> It’s a simple-to-use file-transferring feature that allows sending files without any hassle.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="exampleModalforpop" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
        <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
            <div className="text-center mb-5 mt-3">
            <p className="fs-3 text-center fw-bold"> Our Browser Version Coming Soon </p>
            <img alt='big data sharing platform helibucket' src="https://res.cloudinary.com/dgx4bwfhd/image/upload/v1667815210/New_Project_2_1_o43jrr.png" className="pc-img mt-3"></img>
            <p className="fs-5 text-center mt-3"> Download our desktop version<br/> & get 25 GB free cloud storage </p>
            <button className="btn btn-primary rounded-pill mt-3 pe-5 ps-5" onClick={()=>window.location.href='https://heliwarestorage.s3.us-west-2.amazonaws.com/bucket_data/heli_bucket/HeliBucket_Installer-0.1.exe'}>Download Now</button>
            </div>
        </div>
        
        </div>
    </div>
    </div>

<FooterNew/>
        {/* <div className='footer-heli'>
            <div className='container'>
                <div className='row'>
                    
                    <div className='col-12 col-sm-12 col-md-6 mt-1 t-n-c-f'>
                        <a className='footer-text me-2' href='/terms-and-conditions/'>Terms & Condition</a>
                        <a className='footer-text me-2' href='/use-policy/'>Use Policy</a>
                        <a className='footer-text me-2' href='/return-policy/'>Refund Policy</a>
                        <a className='footer-text me-2' href='/contact/'>Help & Support</a>
                    </div>
                
                    <div className='col-12 col-sm-12 col-md-6'>
                        <p className=' color-aaa copyright-text'>
                        Copyright © 2023 Helibucket.co.in. All Rights Reserved.
                        </p>
                    </div>
                    
                </div>
            </div>
        </div> */}
        
        
        
    </div>
  )
}
