import React, { useState, useEffect, useCallback } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Navigate,
    useNavigate,
  } from "react-router-dom";
import logo from "../../src/components/Images/Heil_bucket_logo.svg";
export default function HeaderNew() {
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        localStorage.removeItem("storageType");
        navigate("/login");
      };
      const getNameFromCookie = localStorage.getItem("name");
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-custom">
        <div className="container-fluid">
          <img src={logo} className="logo-home" />
          <button
            className="navbar-toggler button-hover_none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span>
              <i className="bi bi-list fs-1"></i>
            </span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContenta"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item nav-items">
                <Link className="nav-link" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item nav-items">
                <Link className="nav-link" to="/upload">
                  Upload
                </Link>
              </li>
              <li className="nav-item nav-items">
                <Link className="nav-link " to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item nav-items">
                <Link className="nav-link" to="/contact">
                  Contact Us
                </Link>
              </li>
              {getNameFromCookie !== null ? (
                <li className="nav-item nav-items">
                  {/* <a className="nav-link nav-link1" onClick={handleLogout}>
                      {getNameFromCookie}
                    </a> */}
                  <button
                    type="button"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="profile-ico"
                      src="https://res.cloudinary.com/dgx4bwfhd/image/upload/v1679921563/User_1_wwbynm.png"
                    />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li onClick={handleLogout}>
                      <button className="dropdown-item" type="button">
                        log out <i class="bi bi-box-arrow-in-right"></i>
                      </button>
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="nav-item nav-items">
                  <a className="nav-link nav-link1" href="/login">
                    Login
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <div
        className="collapse navbar-collapse d-lg-none"
        id="navbarSupportedContent"
      >
        <div className="background-not-clickable">
          <div className="child">
            <div className="close-bottom">
              <button
                class="button-hover_none"
                style={{ backgroundColor: "transparent" }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="true"
                aria-label="Toggle navigation"
              >
                <span>
                  <i class="bi bi-x-lg cross fs-4" id="cross"></i>
                </span>
              </button>
            </div>
            <div className="mobile-menu">
              <ul className="navigation">
                <li>
                  <Link className="nav-link" aria-current="page" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" aria-current="page" to="/upload">
                    Upload
                  </Link>
                </li>
                <li>
                  <Link className="nav-link " to="/about">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/contact">
                    Contact Us
                  </Link>
                </li>
                {getNameFromCookie !== null ? (
                  <li className="nav-item" onClick={handleLogout}>
                    <a className="nav-link nav-link1" onClick={handleLogout}>
                      {/* {getNameFromCookie}  */}
                      log out <i class="bi bi-box-arrow-in-right"></i>
                    </a>
                  </li>
                ) : (
                  <li className="nav-item">
                    <a className="nav-link nav-link1" href="/login">
                      Login
                    </a>
                  </li>
                )}
              </ul>
              <div className="text-center text-dark ">
                Copyright © 2023 by &nbsp;
                <Link className="text-dark " href="https://helibucket.co.in/">
                  helibucket.co.in
                </Link>{" "}
                All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
