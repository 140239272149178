import React, { useState, useEffect, useCallback } from "react";
import "../assets/Styles/landing.css";
import bg_image from "../../src/components/Images/casual-life-3d-excited-young-woman-receiving-like-notifications-on-her-laptop 1.png";
import logo from "../../src/components/Images/Heil_bucket_logo.svg";
import big_data from "../../src/components/Images/big_data.svg";
import icon1 from "../../src/components/Images/Icon 1.svg";
import icon2 from "../../src/components/Images/Icon 2.svg";
import icon3 from "../../src/components/Images/Icon 3.svg";
import icon4 from "../../src/components/Images/Icon 4.svg";
import icon5 from "../../src/components/Images/Icon 5.svg";
import upload_icon from "../../src/components/Images/upload_icon.svg";
import NewHeader from '../components/HeaderNew';
import FooterNew from '../components/FooterNew';

import {BrowserRouter as Router,Routes,Route,Link,Navigate,useNavigate,} from "react-router-dom";
export default function LandingPage() {
  const navigate = useNavigate();
  const getEmailFromCookie = localStorage.getItem('email');
  const getNameFromCookie = localStorage.getItem("name");
  const tokenCookie = localStorage.getItem('token');
  const StorageType = localStorage.getItem('storageType');
  const openUploadPage = () => {
    navigate("/upload/");
  };
  const [loader , setLoader] =useState(false)

  const openBacketPage = () => {
    setLoader(true)
    if(getEmailFromCookie===null){
      navigate('/login')
    }
    else{
      const URL="https://helibucket.co.in/apis/file-details-list/";
          const formData = new FormData();
          formData.append('email', getEmailFromCookie);
          formData.append('username', getEmailFromCookie); // Assuming you want to set username to the email value
          formData.append('login_token', tokenCookie);
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body:formData //JSON.stringify({ email: getEmailFromCookie })
        };
				fetch(URL, requestOptions)
				.then(response => response.json())
				.then(data1 => {
					console.log("====>",data1);
					localStorage.setItem('email', getEmailFromCookie);
					if(data1['msg'] === "You have not any Bucket"){
						navigate('/login');
            setLoader(false)

					}
					else {
            // navigate('/dashboard-premium')
						StorageType == "premimum" ?  navigate('/dashboard-premium') : navigate('/dashboard');
            setLoader(false)
          }
					
					});


    }
    
    
  };

  ///here handle logout start
  const handleLogout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("storageType");

    navigate("/login");
  };
  ///here handle logout end

  return (
    <div>
      {/* <nav className="navbar navbar-expand-lg  sticky-top bg-body-custom">
        <div className="container-fluid">
          <img src={logo} className="logo-home" />
          <button
            className="navbar-toggler button-hover_none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span>
              <i className="bi bi-list fs-1"></i>
            </span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContenta"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item nav-items">
                <Link className="nav-link" aria-current="page" to="/upload">
                  Home
                </Link>
              </li>
              <li className="nav-item nav-items">
                <Link className="nav-link " to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item nav-items">
                <Link className="nav-link" to="/contact">
                  Contact Us
                </Link>
              </li>
              {getNameFromCookie !== null ? (
                <li className="nav-item nav-items">
                  <button
                    type="button"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="profile-ico"
                      src="https://res.cloudinary.com/dgx4bwfhd/image/upload/v1679921563/User_1_wwbynm.png"
                    />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li onClick={handleLogout}>
                      <button className="dropdown-item" type="button">
                        log out <i class="bi bi-box-arrow-in-right"></i>
                      </button>
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="nav-item nav-items">
                  <a className="nav-link nav-link1" href="/login">
                    Login
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <div
        className="collapse navbar-collapse d-lg-none"
        id="navbarSupportedContent"
      >
        <div className="background-not-clickable">
          <div className="child">
            <div className="close-bottom">
              <button
                class="button-hover_none"
                style={{ backgroundColor: "transparent" }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="true"
                aria-label="Toggle navigation"
              >
                <span>
                  <i class="bi bi-x-lg cross fs-4" id="cross"></i>
                </span>
              </button>
            </div>
            <div className="mobile-menu">
              <ul className="navigation">
                <li>
                  <Link className="nav-link" aria-current="page" to="/upload">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="nav-link " to="/about">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/contact">
                    Contact Us
                  </Link>
                </li>
                {getNameFromCookie !== null ? (
                  <li className="nav-item" onClick={handleLogout}>
                    <a className="nav-link nav-link1" onClick={handleLogout}>
                      log out <i class="bi bi-box-arrow-in-right"></i>
                    </a>
                  </li>
                ) : (
                  <li className="nav-item">
                    <a className="nav-link nav-link1" href="/login">
                      Login
                    </a>
                  </li>
                )}
              </ul>
              <div className="text-center text-dark ">
                Copyright © 2023 by &nbsp;
                <Link className="text-dark " href="https://helibucket.co.in/">
                  helibucket.co.in
                </Link>{" "}
                All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <NewHeader/>
      <div className="blue_div">
        <div className="main-hero">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 ">
              <div className="my-custom-card">
                <div className="Heading_div">
                  <h5 className="heading ">Instant Transfer</h5>
                </div>
                <div class="container container-l">
                  <ul class="myUL">
                    <li className="btn-group pb-2">
                      <div className="circle me-3 mt-1"></div>
                      <div>Send Upto 5 GB</div>{" "}
                    </li>
                    <li className="btn-group pb-2">
                      <div className="circle me-3 mt-1"></div>
                      <div>No Need To Sign In</div>{" "}
                    </li>
                    <li className="btn-group pb-2">
                      <div className="circle me-3 mt-1"></div>
                      <div>Via Link or Email</div>{" "}
                    </li>
                  </ul>
                </div>
                <div onClick={openUploadPage} className="text-center btn-group">
                  <div style={{ "z-index": "1000" }}>
                    <img className="upload-bucket" src={upload_icon} />
                  </div>
                  <div className="access_buttom">
                    <button className="send_now_btn">
                      &nbsp;Send now
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 ps-sm-2 ps-md-5">
              <div className="my-custom-card me-sm-2 me-md-5">
                <div className="Heading_div">
                  <h5 className="heading ">Big Data Dashboard</h5>
                </div>
                <div class="container container-l">
                  <ul class="myUL">
                    <li className="btn-group pb-2">
                      <div className="circle me-3 mt-1"></div>
                      <div>Seamless Sharing</div>{" "}
                    </li>
                    <li className="btn-group pb-2">
                      <div className="circle me-3 mt-1"></div>
                      <div>Collaboration</div>{" "}
                    </li>
                    <li className="btn-group pb-2">
                      <div className="circle me-3 mt-1"></div>
                      <div>Effortless Data reception</div>{" "}
                    </li>
                  </ul>
                </div>
                <div onClick={openBacketPage} className="text-center btn-group">
                  <div style={{ "z-index": "1000" }}>
                    <img className="upload-bucket" src={big_data} />
                  </div>
                  <div class="access_buttom">
                    <button className="access_now">
                      &nbsp; Access Bucket <span class={`spinner-border spinner-border-sm ${loader ? null:'d-none' } `} role="status" aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 d-md-none d-sm-none d-none d-lg-flex ImageRight_div">
              <img src={bg_image} className="bgImageRight" />
            </div>
          </div>

          
        </div>

        <div className="row Features  ">
            <div className="col-12 col-lg-2 col-sm-3">
              <div class="featuresIcon btn-group">
                <img src={icon1} alt="" srcset="" />
                <span className="mt-3 ps-1">Data Security</span>
              </div>
            </div>
            <div className="col-12 col-lg-2 col-sm-3">
              <div class=" featuresIcon btn-group">
                <img src={icon2} />
                <span className="mt-3 ps-1">10X faster Transfer Speed</span>
              </div>
            </div>
            <div className="col-12 col-lg-2 col-sm-3">
              <div class=" featuresIcon btn-group">
                <img src={icon3} />
                <span className="mt-3 ps-1">Centralize Big Data</span>
              </div>
            </div>
            <div className="col-12 col-lg-2 col-sm-3">
              <div class="featuresIcon btn-group">
                <img src={icon4} />
                <span className="mt-3 ps-1">Interactive UI</span>
              </div>
            </div>
            <div className="col-12 col-lg-2 col-sm-3">
              <div class="featuresIcon btn-group">
                <img src={icon5} />
                <span className="mt-3 ps-1">Easy To access</span>
              </div>
            </div>
          </div>
      </div>
      <div className="Hero_text">
        <h1>Data security is our top most priority.</h1>
        <p>
          Helibucket.co.in is a website that provides a free and easy-to-use
          file transfer and storage service. With their service, users can
          instantly transfer up to 5GB of files without any hassle.
          Additionally, they offer premimum cloud storage of up to 25GB to keep
          files safe and easily accessible from anywhere. Helibucket's services
          are completely free and do not require any signup or registration
          process, making it a convenient and reliable option for quick and
          secure file sharing and storage.
        </p>
      </div>
      <section class="pricing-bg-color">
          <main className="main flow">
            <h1 className="main__heading">Pricing</h1>
            <div className="main__cards cards">
              <div className="cards__inner">
                <div className="cards__card cardz">
                  <h2 className="card__heading">Basic</h2>
                  <h2 className="card__subheading">25 GB</h2>
                  <p className="card__price">₹499</p>
                  <ul role="list" className="card__bullets flow">
                    <li>Tranfer upto 25 GB / month</li>
                    <li>File Preview</li>
                    <li>Interactive Dashboard</li>
                    <li>Multi-Level Secure File Sharing</li>
                    <li>Comment Resolution System</li>
                    <li>24/7 Priority Email support</li>
                  </ul>
                  <Link to="/contact" target="_blank" className="card__cta cta">
                    Get Started
                  </Link>
                  
                </div>
                <div className="cards__card cardz">
                  <h2 className="card__heading">Pro</h2>
                  <h2 className="card__subheading">500 GB</h2>
                  <p className="card__price">₹7,499</p>
                  <ul role="list" className="card__bullets flow">
                    <li>Tranfer upto 500 GB / month</li>
                    <li>File Preview</li>
                    <li>Interactive Dashboard</li>
                    <li>Multi-Level Secure File Sharing</li>
                    <li>Comment Resolution System</li>
                    <li>24/7 Priority Email support</li>
                  </ul>
                  <Link to="/contact" target="_blank" className="card__cta cta">
                    Upgrade to Pro
                  </Link>
                  
                </div>
                <div className="cards__card cardz">
                  <h2 className="card__heading">Custom</h2>
                  <h2 className="card__customsubheading">Recommended For Corporates</h2>
                  <p className="card__Customprice">Price will be Customizable</p>
                  <ul role="list" className="card__bullets flow">
                    <li>Buy Storage as per your need</li>
                    <li>Can be customized for Teams</li>
                    <li>File Preview</li>
                    <li>Interactive Dashboard</li>
                    <li>Multi-Level Secure File Sharing</li>
                    <li>Comment Resolution System</li>
                    <li>24/7 Priority Email support</li>
                  </ul>
                  <Link to="/contact" target="_blank" className="card__cta cta">
                    Contact Us
                  </Link>
                </div>
              </div>
              <div className="overlay cards__inner" />
            </div>
          </main>

      </section>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div
            className="col-lg-6 col-sm-12 bg-img-left aos-init aos-animate"
            data-aos="fade-right"
            data-aos-offset={300}
            data-aos-easing="ease-in-sine"
          />
          <div
            className="col-lg-6 col-sm-12 aos-init aos-animate container_text"
            data-aos="fade-left"
            data-aos-easing="ease-in-sine"
            data-aos-offset={300}
          >
            <p className="ubuntu f-40">
              Why use <span className="highlight">Helibucket</span>?
            </p>
            <p className="textNormal">
              Helibucket, as a cloud data storage platform, provides users with
              a convenient and secure way to store and access their digital
              files from anywhere with an internet connection. Here are some
              reasons why someone might choose to use a service like Helibucket:
            </p>
            <ul>
              <li className="textNormal">
                <strong>Accessibility:</strong> With a cloud storage service
                like Helibucket, users can access their files from anywhere with
                an internet connection. This means that they can easily share
                files with colleagues, access files on-the-go, and collaborate
                on projects with others.
              </li>
              <li className="textNormal">
                <strong>Security:</strong> Cloud storage services like
                Helibucket typically have strong security measures in place to
                protect users' data. This may include encryption, multi-factor
                authentication, and regular backups.
              </li>
              <li className="textNormal">
                <strong>Storage capacity:</strong> With 25GB of premimum storage for
                logged-in users, Helibucket provides a generous amount of space
                for users to store their files. This can be particularly useful
                for people who need to store large files or a large number of
                files.
              </li>
              <li className="textNormal">
                <strong>Instant data transfer:</strong> The 5GB instant data
                transfer facility offered by Helibucket can be a convenient way
                for people to quickly send files to others without having to
                sign up for an account or use a separate file transfer service.
              </li>
            </ul>
            <p className="textNormal">
              Overall, Helibucket can be a useful tool for people who need to
              store, access, and share digital files. The service's combination
              of accessibility, security, and storage capacity can make it an
              attractive option for individuals and businesses alike.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12 aos-init aos-animate container_text" data-aos="fade-left" data-aos-easing="ease-in-sine" data-aos-offset={300}>
            <p className="ubuntu f-40">
            Advancements with <span className="highlight">Helibucket</span>
            </p>
            
            <ul>
            <li className="textNormal">
              <strong>Integrations and Interoperability:</strong> The future of data transfer lies in seamless integrations and interoperability with other systems and platforms. HeliBucket is designed to integrate with existing government infrastructure, enabling smooth data transfer between different systems and applications. This interoperability enhances efficiency, streamlines workflows, and promotes data sharing across government agencies, fostering collaboration and informed decision-making.
            </li>
              <li className="textNormal">
              <strong>Embracing Emerging Technologies:</strong> Looking ahead, HeliBucket is poised to embrace emerging technologies that will shape the future of data transfer. From leveraging advancements in artificial intelligence and machine learning for automated data transfers to harnessing blockchain technology for enhanced data security and transparency, HeliBucket remains at the forefront of innovation, continuously evolving to meet the evolving needs of government agencies.

              </li>
              <li className=" textNormal">
              <strong>Geospatial Processing Tools:</strong> Unleashing Data Insights:  HeliBucket's desktop application comes equipped with user-friendly geospatial processing tools, making it a comprehensive solution for professionals in fields such as geography, urban planning, and environmental analysis. These intuitive tools enable users to process, analyze, and visualize geospatial data within a single platform. With HeliBucket, organizations can uncover valuable insights, identify patterns, and drive informed decision-making.
              </li>
            </ul>
            
          </div>
          <div
            className="col-lg-6  col-sm-12 bg-img-right aos-init aos-animate"
            data-aos="fade-right"
            data-aos-offset={300}
            data-aos-easing="ease-in-sine"
          />
        </div>
      </div>


      <FooterNew/>
      
    </div>
  );
}
